import { useTheme } from "styled-components";

const useButtonConfig = () => {
  const theme = useTheme();
  const isDarkMode = localStorage.themeMode === "dark";

  const secondaryButtonColors = {
    colorTextLightSolid: theme.colors.text.t5,
    colorPrimary: theme.colors.background.b1,
    colorPrimaryHover: theme.colors.background.b2,
    colorPrimaryActive: theme.colors.background.b3,
    // disabled
    colorTextDisabled: theme.colors.text.t2,
    borderColorDisabled: theme.colors.stroke.s1,
    colorBgContainerDisabled: theme.colors.background.b1,
  };

  const config = {
    primary: {
      colorPrimary: theme.colors.primary.p3,
      colorPrimaryHover: theme.colors.primary.p4,
      colorPrimaryActive: isDarkMode ? theme.colors.primary.p3 : theme.colors.primary.p5,
    },
    secondary: {
      ...secondaryButtonColors,
    },
    "secondary-info": {
      ...secondaryButtonColors,
      colorTextLightSolid: theme.colors.link.l3,
    },
    "secondary-success": {
      ...secondaryButtonColors,
      colorTextLightSolid: theme.colors.success.s3,
    },
    "secondary-warning": {
      ...secondaryButtonColors,
      colorTextLightSolid: theme.colors.warning.w3,
    },
    tertiary: {
      colorTextLightSolid: theme.colors.text.t5,
      colorPrimary: theme.colors.background.b3,
      colorPrimaryHover: theme.colors.background.b4,
      colorPrimaryActive: isDarkMode ? theme.colors.background.b3 : theme.colors.background.b4,
    },
    success: {
      colorPrimary: theme.colors.success.s3,
      colorPrimaryHover: theme.colors.success.s4,
      colorPrimaryActive: isDarkMode ? theme.colors.success.s3 : theme.colors.success.s5,
    },

    info: {
      colorPrimary: theme.colors.link.l3,
      colorPrimaryHover: theme.colors.link.l4,
      colorPrimaryActive: isDarkMode ? theme.colors.link.l3 : theme.colors.link.l5,
    },
    warning: {
      colorPrimary: theme.colors.warning.w3,
      colorPrimaryHover: theme.colors.warning.w4,
      colorPrimaryActive: isDarkMode ? theme.colors.warning.w3 : theme.colors.warning.w5,
    },
  };

  return { config };
};

export default useButtonConfig;
