import * as yup from "yup";
import { inputs, MIN_LENGTH } from "./common";

const ORGANIZATION_MAX_LENGTH = 49;
const OTP_LENGTH = 6;

export const registerInputs = {
  organization: yup
    .string()
    .trim()
    .required("Required")
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(ORGANIZATION_MAX_LENGTH, `Shouldn't exceed ${ORGANIZATION_MAX_LENGTH} characters`),
  application: yup
    .string()
    .trim()
    .required("Required")
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(ORGANIZATION_MAX_LENGTH, `Shouldn't exceed ${ORGANIZATION_MAX_LENGTH} characters`),
  name: inputs.fullname,
  email: inputs.email.required("Email Address is required"),
  password: inputs.password,
  emailCode: inputs.number
    .length(OTP_LENGTH, `Should contain ${OTP_LENGTH} digits code`)
    .required("OTP is required"),
};
