/* eslint-disable react/require-default-props */
import { forwardRef, memo } from "react";
import { Controller } from "react-hook-form";

import { InputField as InputFieldAtom } from "src/krispcall/components/atoms/field/v3";
import { useFieldError } from "src/krispcall/components/organisms/form";
import { LabelV3 } from "src/krispcall/components/atoms";

const InputField = forwardRef(function TextInput(props, ref) {
  const { id = "", isEditable = false, label, wrapperClassName, dataCy, ...rest } = props;

  const { fieldStatus, errorElement } = useFieldError({
    name: rest?.name,
  });

  const inputFieldClassName = isEditable ? `truncate ${rest?.className}` : rest?.className;

  return (
    <div className={wrapperClassName} data-cy={dataCy}>
      {label && (
        <LabelV3 disabled={rest?.disabled} id={id || rest?.name}>
          {label}
        </LabelV3>
      )}
      <Controller
        name={rest?.name}
        control={rest?.control}
        render={({ field }) => {
          return (
            <InputFieldAtom
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              id={id || rest?.name}
              ref={ref}
              value={rest?.value || field.value}
              status={rest?.status || fieldStatus}
              variant={rest?.variant || (isEditable ? "borderless" : undefined)}
              className={inputFieldClassName}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rest}
            />
          );
        }}
      />
      {errorElement}
    </div>
  );
});

export default memo(InputField);
