import styled from "styled-components";
import { Button as AntButton } from "antd";

const Button = styled(AntButton)`
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width:${props => props.width || "100%"};
  background-color: #5301B1;
  border:0px;
`;

export default Button;
