import { Crisp } from "crisp-sdk-web";
import { datadogLogs } from "@datadog/browser-logs";

import { getAllUrlParams } from "../utils/getAllUrlParams";
import { CRISP_WEBSITE_ID } from "../constants/KEYS";

export const useChatBot = () => {

  const isCrispInjected = Crisp.isCrispInjected();

  const clearCrispLocalStorage = () => {
    const CRISP_LS_KEYS = Object.keys(localStorage).filter(key => key.includes("crisp"));
    CRISP_LS_KEYS.forEach(name => localStorage.removeItem(name));
  };

  const trackUtmCodes = () => {
    const urlParams = getAllUrlParams(window.location.search);
    // {eslint-disable-next-line @typescript-eslint/naming-convention}
    const { utm_campaign, utm_content, utm_medium, utm_source } = urlParams || {};
    if (CRISP_WEBSITE_ID) {
      Crisp.session.setData({
        utm_campaign: utm_campaign || "",
        utm_content: utm_content || "",
        utm_medium: utm_medium || "",
        utm_source: utm_source || "",
      });
    }
  };

  const resetCrispSession = () => {
    if (CRISP_WEBSITE_ID) {
      Crisp.session?.reset();
    }
    clearCrispLocalStorage();
  };
  const initCrispChat = (tokenId) => {
    try {
      if (CRISP_WEBSITE_ID) {
        if (tokenId && isCrispInjected) {
          resetCrispSession();
        }
        Crisp.configure(CRISP_WEBSITE_ID, { tokenId });
        trackUtmCodes();
        if (tokenId) {
          Crisp.setTokenId(tokenId);
        }

        if (isCrispInjected) {
          Crisp.session.reset();
        }
      }
    } catch (err) {
      datadogLogs?.logger?.error("Unable to configure crisp chat", {
        dataDogError: err,
        context: "crisp chat",
      });
    }
  };

  const openChat = () => {
    if (CRISP_WEBSITE_ID) {
      Crisp.chat.open();
      return;
    }
  };

  const verifyChat = () => {
    Crisp.chat.open();
    Crisp.message.show(
      "text",
      `Hi there!,
        Welcome to KrispCall!
      `
    );
    Crisp.message.show(
      "text",
      "To verify your identity (KYC), we have specific requirements that must be met for the submitted proof. Please ensure the following details are clearly visible in the photo or screenshot of the proof: 1] If submitting a bank statement, it should clearly display the card issuer's name/logo, the card holder's name, the last four digits of the card, and the payment made to KrispCall. 2] If submitting a photo of the card, it should clearly show the card issuer's name/logo, the card holder's name, and the last four digits of the card. Additionally, If the cardholder's name is not visible in the statement or card image, a separate government-issued ID is required, and the name must exactly match the one registered during payment."
    );

  };
  const hideChat = () => {
    if (CRISP_WEBSITE_ID) {
      Crisp.setVacationMode(true); // to completely hide chatbox
      return;
    }
  };

  const updateCrispEmail = (email) => {
    if (CRISP_WEBSITE_ID && email) Crisp.user.setEmail(email);
  };

  const updateCrispUserName = (name) => {
    if (CRISP_WEBSITE_ID && name) Crisp.user.setNickname(name);
  };
  const updateCrispAvatar = (avatarUrl) => {
    if (CRISP_WEBSITE_ID && avatarUrl) Crisp.user.setAvatar(avatarUrl);
  };
  const updateSessionUserId = (tokenId) => {
    if (CRISP_WEBSITE_ID && tokenId) {
      Crisp.session.setData({
        user_id: tokenId,
      });
    }
  };
  const updateUserRole = (role) => {
    if (CRISP_WEBSITE_ID && role) {
      Crisp.session.setData({
        role,
      });
    }
  };
  const updateUserWorkspaceDetails = ({
    workspaceName,
    workspaceStatus,
    workspacePlan,
    subscriptionStatus,
  }) => {
    if (CRISP_WEBSITE_ID) {
      Crisp.session.setData({
        workspace_name: workspaceName,
        workspace_status: workspaceStatus,
        plan: workspacePlan,
        subscription_status: subscriptionStatus,
      });
    }
  };
  const updateNumberDetail = (totalNumbers) => {
    if (CRISP_WEBSITE_ID) {
      Crisp.session.setData({
        numbers: totalNumbers,
      });
    }
  };

  const updateActiveNumberList = (allNumbers) => {
    const hasActiveNumbers = allNumbers?.length > 0;
    if (CRISP_WEBSITE_ID) {
      Crisp.session.setData({
        active_numbers_list: hasActiveNumbers ? allNumbers.join(", ") : 0,
      });
    }
  };
  const updateMemberDetail = (numberOfMembers) => {
    if (CRISP_WEBSITE_ID) {
      Crisp.session.setData({
        members: numberOfMembers,
      });
    }
  };

  const setCrispUser = (email, name, avatarUrl, tokenId) => {
    updateCrispEmail(email);
    updateCrispUserName(name);
    updateCrispAvatar(avatarUrl);
    updateSessionUserId(tokenId);
  };

  return {
    Crisp,
    initCrispChat,
    openChat,
    hideChat,
    setCrispUser,
    updateCrispEmail,
    updateCrispUserName,
    updateCrispAvatar,
    resetCrispSession,
    clearCrispLocalStorage,
    updateUserWorkspaceDetails,
    updateNumberDetail,
    updateMemberDetail,
    updateUserRole,
    updateActiveNumberList,
    verifyChat,
  };
};
