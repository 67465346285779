// copied from SelfLoginPage

import React from "react";

import { authConfig } from "src/auth/Auth";

import SigninPageCustom from "./SigninPageCustom";

class SelfLoginPageCustom extends React.Component {
  constructor(props) {
    super(props);
    import("src/ManagementPage");
    this.state = {
      type: localStorage.getItem("params") ? "code" : "login",
    };
  }

  render() {
    return (
      <SigninPageCustom type={this.state.type} mode={"signin"} applicationName={authConfig.appName} {...this.props} />
    );
  }
}

export default SelfLoginPageCustom;
