import { css } from "styled-components";

const antProgress = css`
  .css-dev-only-do-not-override-nip9vw.ant-progress-line,
  #password-progress.ant-progress .ant-progress-inner {
    height: 4px;
    line-height: 0px;
    margin-top: 6px;
  }
`;

export default antProgress;
