import { useState, useEffect } from "react";

import theme from "src/krispcall/themes";

export default function useThemeSwitcher() {
  const html = document.documentElement;
  if (!localStorage.themeMode) localStorage.setItem("themeMode", "light");

  const [themeMode, setThemeMode] = useState(localStorage.themeMode);
  const [selectedTheme, setSelectedTheme] = useState(
    theme[localStorage.themeMode]
  );

  // toggle theme
  const toggleTheme = () => {
    if (themeMode === "light") {
      localStorage.setItem("themeMode", "dark");
      setThemeMode("dark");
      html.classList.add("dark");
    } else {
      localStorage.setItem("themeMode", "light");
      setThemeMode("light");
      html.classList.remove("dark");
    }

    setSelectedTheme(theme[localStorage.themeMode]);
  };

  // tailwindcss
  useEffect(() => {
    // On page load or when changing themeModes, best to add inline in `head` to avoid FOUC
    const isDarkModeActivated =
      localStorage.themeMode === "dark" ||
      (!("themeMode" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches);

    if (isDarkModeActivated) html.classList.add("dark");
    else html.classList.remove("dark");

    setSelectedTheme(theme[localStorage.themeMode]);
  }, [html.classList]);

  return {
    selectedTheme,
    setSelectedTheme,
    themeMode,
    toggleTheme,
    setThemeMode,
  };
}
