// Copied from self forget page

import React from "react";

import { authConfig } from "src/auth/Auth";

import ForgotPageCustom from "./ForgotPageCustom";

class SelfForgetPageCustom extends React.Component {
  render() {
    return (
      <ForgotPageCustom
        type={"forgotPassword"}
            applicationName={
                authConfig.appName
            }
        {...this.props}
      />
    );
  }
}

export default SelfForgetPageCustom;
