import { css } from "styled-components";

const antPopover = css`
  .ant-popover {
    .ant-popover-inner {
      border: 1px solid ${({ theme }) => theme.colors.stroke.s1};
    }
  }
`;

export default antPopover;
