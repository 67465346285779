import OtpInput from "react-otp-input";
import { BiMinus } from "react-icons/bi";

const OtpInputField = props => {
  const {
    setEmailOtpCode,
    numInputs,
    containerStyle,
    value,
  } = props;

  const separatorPosition = ((numInputs ? numInputs : 6) / 2) - 1;

  return (
    <OtpInput
      containerStyle={containerStyle}
      onChange={(e) => {setEmailOtpCode(e);}}
      numInputs={numInputs ? numInputs : 6}
      value={value}
      inputStyle={"otp-input-field-item"}
      inputType="number"
      renderInput={(inputProps, index) => <><input {...inputProps} required /> {index === separatorPosition ? <BiMinus size={20} style={{marginRight: "10px"}} color="#6E6681" /> : ""}</>}
    />
  );
};

export default OtpInputField;
