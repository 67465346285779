export const useGetRecaptchaV3Token = async(siteKey) => {
    return new Promise((resolve, reject) => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(siteKey, { action: "submit" })
                .then((token) => {
                    resolve(token); // Resolve the promise with the token
                })
                .catch((error) => {
                    console.error("Error executing reCAPTCHA: ", error);
                    reject(error); // Reject the promise if there's an error
                });
        });
    });
  };
