import { Button } from "antd";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  &.ant-btn {
    &,
    &-sm {
      > .ant-btn-icon {
        > svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    &-lg {
      > .ant-btn-icon {
        > svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`;
