import { memo } from "react";

const FieldError = ({ message, className }) => {
  return (
    <div
      className={`text-error-600 font-heebo text-sm leading-4 ${className}`}
      data-testid="field-error"
    >
      {message}
    </div>
  );
};

FieldError.defaultProps = {
  message: "",
  className: "",
};

export default memo(FieldError);
