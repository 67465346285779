export const tags = {
  blue: "#6D8DEF",
  orchid: "#AA6DEF",
  magenta: "#EF6DC2",
  lilac: "#E247F0",
  vermillion: "#EF6D6D",
  grape: "#7A6DEF",
  pacific: "#6DC2EF",
  lime: "#B0EF6D",
  pear: "#6DEFA5",
  cyan: "#6DEFE4",
  mustard: "#F7E56E",
  amber: "#EFB36D",
};

export const gradient = {
  ga: "linear-gradient(180deg, #8E2DE2 0%, #6927ED 100%)",
};

export const lightColors = {
  primary: {
    p1: "#F2EBF9",
    p2: "#DDCCEF",
    p3: "#5301B1", // default
    p4: "#4B01A0", // hover
    p5: "#3E0185",
    p6: "#32016A",
  },
  background: {
    b1: "#fff",
    b2: "#faf9fb",
    b3: "#f5f4f6",
    b4: "#edebee",
  },
  stroke: {
    s1: "#E5E4E7",
    s2: "#cbc9cf",
  },
  text: {
    t1: "#fff", // always white
    t2: "#99949e", // disabled
    t3: "#66616b", // component2
    t4: "#4d4950", // paragraph
    t5: "#333036", // component
    t6: "#1a181b", // headers
  },
  link: {
    l1: "#EBF5FF",
    l2: "#CCE4FF",
    l3: "#007AFF", // default
    l4: "#0062CC",
    l5: "#0062CC",
    l6: "#004999",
  },
  success: {
    s1: "#ECFAF0",
    s2: "#CFF2D8",
    s3: "#10bc3b", // default
    s4: "#0EAA35",
    s5: "#0D962F",
    s6: "#0A7123",
  },
  error: {
    e1: "#FFEFEF",
    e2: "#FFD6D6",
    e3: "#FF3333", // default
    e4: "#E62E2E",
    e5: "#CC2929",
    e6: "#991F1F",
  },
  warning: {
    w1: "#FFF7EB",
    w2: "#FFEACC",
    w3: "#FF9500",
    w4: "#E68600",
    w5: "#CC7700",
    w6: "#995900",
  },
  components: {
    tooltip: {
      background: "#141216",
      bodyText: "#CCC9CF",
    },
    avatar: {
      user: "#BFBCC2",
    },
    callState: {
      background: "#fff",
      recordBar: "#D9D5DD",
    },
    tabs: {
      background: "#f5f4f6",
      active: "#fff",
    },
    leftBar: {
      background: "#000",
    },
    modal: {
      background: "#fff",
    },
    dropdown: {
      background: "#fff",
      hover: "#FAF9FB",
      active: "#F5F4F6",
    },
  },
  tags,
  gradient,
};
export const darkColors = {
  primary: {
    p1: "#1D003D",
    p2: "#300166",
    p3: "#5F01CB", // default
    p4: "#7727D3", // hover
    p5: "#8F4DDA",
    p6: "#BF99EA",
  },
  background: {
    b1: "#141216",
    b2: "#19171C",
    b3: "#1F1C22",
    b4: "#242027",
  },
  stroke: {
    s1: "#26232A",
    s2: "#333036",
  },
  text: {
    t1: "#FFFFFF", // always white
    t2: "#66616B", // disabled
    t3: "#B2AFB6", // component2
    t4: "#CCC9CF", // paragraph
    t5: "#E5E4E7", // component
    t6: "#F3F2F3", // headers
  },
  link: {
    l1: "#08294D",
    l2: "#0D4480",
    l3: "#1987FF", // default
    l4: "#0062CC",
    l5: "#5EABFF",
    l6: "#A3CFFF",
  },
  success: {
    s1: "#054014",
    s2: "#096A21",
    s3: "#12D342", // default
    s4: "#35DA5E",
    s5: "#59E07A",
    s6: "#A0EDB3",
  },
  error: {
    e1: "#4D1717",
    e2: "#802626",
    e3: "#FF4C4C", // default
    e4: "#FF6767",
    e5: "#FF8181",
    e6: "#FFB7B7",
  },
  warning: {
    w1: "#4D3008",
    w2: "#80500D",
    w3: "#FFA019",
    w4: "#FFAE3B",
    w5: "#FFBC5E",
    w6: "#FFD9A3",
  },
  components: {
    tooltip: {
      background: "#232026",
      bodyText: "#CCC9CF",
    },
    avatar: {
      user: "#7A7580",
    },
    callState: {
      background: "#2E2932",
      recordBar: "#141316",
    },
    tabs: {
      background: "#0A090B",
      active: "#19171C",
    },
    leftBar: {
      background: "#000",
    },
    modal: {
      background: "#19171C",
    },
    dropdown: {
      background: "#1F1C22",
      hover: "#242027",
      active: "#29252D",
    },
  },
  tags,
  gradient,
};
