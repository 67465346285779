import { Checkbox } from "antd";
import { Controller } from "react-hook-form";

const CheckboxInput = props => {
  const { id, name, label, control, className, disabled, onChange, ...rest } = props;

  return (
    <>
      {!control && <Checkbox disabled={disabled} onChange={onChange} {...rest} />}
      {control && (
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <Checkbox
                {...field}
                id={id}
                checked={field.value}
                value={field.value}
                disabled={disabled}
                onChange={(event) => {
                  field.onChange((event.target)?.checked);
                }}
                className={className}
              >
                {label}
              </Checkbox>
            );
          }}
        />
      )}
    </>
  );
};

CheckboxInput.displayName = "CheckboxInput";
export default CheckboxInput;
