import styled from "styled-components";

const Wrapper = styled.h2`
    color: #1F1F1F;
    line-height:31.2px;
    margin-bottom:8px;
    font-weight:700;
    margin-bottom:0px;
    margin-top:0px";
    font-size:1.125rem;
`;

const SubTitle = ({ children, className}) => {
    return <Wrapper className={className}>{children}</Wrapper>;
};
export default SubTitle;
