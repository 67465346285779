import React, { useEffect } from "react";

import {UserOutlined} from "@ant-design/icons";
import { Form, Input} from "antd";
import i18next from "i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import { CountryCodeSelect } from "src/common/select/CountryCodeSelect";
import { EmailMfaType, SmsMfaType } from "src/auth/MfaSetupPage";
import { mfaAuth } from "src/auth/mfa/MfaVerifyForm";
import { Text } from "src/krispcall/components/atoms";
import { BackIcon } from "src/krispcall/assets";
import { useChatBot } from "src/krispcall/hooks/useChatBot";
import { SendCodeInputCustom } from "src/krispcall/components/organisms/send-code-input/SendCodeInputCustom";

import * as Setting from "src/Setting";

export const MfaVerifySmsForm = ({mfaProps, application, onFinish, method, user, setEmailOtpCode, state, updateFormValues}) => {
  const [dest, setDest] = React.useState("");
  const {openChat} = useChatBot();
  const [form] = Form.useForm();
  useEffect(() => {
    if (method === mfaAuth) {
      setDest(mfaProps.secret);
      return;
    }
    if (mfaProps.mfaType === SmsMfaType) {
      setDest(user.phone);
      return;
    }

    if (mfaProps.mfaType === EmailMfaType) {
      setDest(user.email);
    }
  }, [mfaProps.mfaType]);

  const isShowText = () => {
    if (method === mfaAuth) {
      return true;
    }
    if (mfaProps.mfaType === SmsMfaType && user.phone !== "") {
      return true;
    }
    if (mfaProps.mfaType === EmailMfaType && user.email !== "") {
      return true;
    }
    return false;
  };

  const isEmail = () => {
    return mfaProps.mfaType === EmailMfaType;
  };

  return (
    <Form
      form={form}
      onFinish={() => {
          onFinish({passcode: state?.code});
        }
      }
      initialValues={{
        countryCode: mfaProps.countryCode,
      }}
      size="large"
    >
      {isShowText() ?
        <div style={{ marginBottom: 20, textAlign: "left", gap: 8 }}>
          {
            isEmail() ?
              <Text className="text-base mb-6">Please enter the 6-digit code we just sent you at <b>{dest}</b> to continue</Text> :
              i18next.t("mfa:Your phone is")
          }
        </div> :
        (<React.Fragment>
          <p>{isEmail() ? i18next.t("mfa:Please bind your email first, the system will automatically uses the mail for multi-factor authentication") :
            i18next.t("mfa:Please bind your phone first, the system automatically uses the phone for multi-factor authentication")}
          </p>
          <Input.Group compact style={{width: "300Px", marginBottom: "30px"}}>
            {isEmail() ? null :
              <Form.Item
                name="countryCode"
                noStyle
                rules={[
                  {
                    required: false,
                    message: i18next.t("signup:Please select your country code!"),
                  },
                ]}
              >
                <CountryCodeSelect
                  initValue={mfaProps.countryCode}
                  style={{width: "30%"}}
                  countryCodes={application.organizationObj.countryCodes}
                />
              </Form.Item>
            }

            <Form.Item
              name="dest"
              noStyle
              rules={[{required: true, message: i18next.t("login:Please input your Email or Phone!")}]}
            >
              <Input
                style={{width: isEmail() ? "100% " : "70%"}}
                onChange={(e) => {setDest(e.target.value);}}
                prefix={<UserOutlined />}
                placeholder={isEmail() ? i18next.t("general:Email") : i18next.t("general:Phone")}
              />
            </Form.Item>
          </Input.Group>
        </React.Fragment>
        )
      }

      <SendCodeInputCustom
        name="passcode"
        application={application}
        state={state}
        setEmailOtpCode={setEmailOtpCode}
        method={method}
        onButtonClickArgs={[mfaProps.secret || dest, isEmail() ? "email" : "phone", Setting.getApplicationName(application)]}
      />

      <Link
        to={"#"}
        className="text-info"
        target={"_self"}
        onClick={() => updateFormValues("getVerifyTotp", undefined)}
      >
        {i18next.t("useDifferentEmail", "Use a different email address.")}
      </Link>
      <br></br>
      <Link
        to={"#"}
        className="text-info"
        target={"_self"}
        onClick={openChat}
      >
        {i18next.t("Contact support", "Contact support")}
      </Link>
      <div className="back-btn mt-10" onClick={() => updateFormValues("getVerifyTotp", undefined)}>
        <img src={BackIcon} height={"20px"} />
        <Text className="font-semibold">Go Back</Text>
      </div>
    </Form>
  );
};

export default MfaVerifySmsForm;
