import { Tooltip as AntTooltip } from "antd";
import styled from "styled-components";

const StyledTooltip = styled(AntTooltip)``;

const Tooltip = ({ ...props }) => {
  return (
    <>
      <StyledTooltip data-cy="tool-tip" {...props} />
    </>
  );
};

export default Tooltip;
