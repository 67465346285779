export function getCaptchaProviderItems(application) {
    const providers = application?.providers;

    if (providers === undefined || providers === null) {
      return null;
    }

    return providers.filter(providerItem => {
      if (providerItem.provider === undefined || providerItem.provider === null) {
        return false;
      }

      return providerItem.provider.category === "Captcha";
    });
}

export function CaptchaProvider(application) {
    const captchaProviderItems = getCaptchaProviderItems(application);
    if (captchaProviderItems.some(providerItem => providerItem.rule === "None") || captchaProviderItems.length === 0) {
      return null;
    }
    const alwaysProviderItems = captchaProviderItems.filter(providerItem => providerItem.rule === "Always");
    const dynamicProviderItems = captchaProviderItems.filter(providerItem => providerItem.rule === "Dynamic");
    const provider = alwaysProviderItems.length > 0
      ? alwaysProviderItems[0].provider
        : dynamicProviderItems[0].provider;

    return provider;
}
