import { memo } from "react";

const Label = ({ id, children, className, isRequired, disabled }) => {
  const disabledClassName = disabled ? "text-text-200" : "text-text-500";
  return (
    <label
      htmlFor={id}
      className={`font-450 block text-sm  mb-1 ${disabledClassName} ${className}`}
    >
      {children}
      {isRequired && (
        <span className="text-error" data-testid="label-required">
          {" "}
          *
        </span>
      )}
    </label>
  );
};

Label.defaultProps = {
  id: "",
  className: "",
  isRequired: false,
  disabled: false,
};

export default memo(Label);
