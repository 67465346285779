import { useEffect } from "react";

import { useChatBot } from "src/krispcall/hooks/useChatBot";
import { CRISP_WEBSITE_ID } from "src/krispcall/constants/KEYS";

const CrispChat = () => {

    const { initCrispChat } = useChatBot();
    const params = localStorage.getItem("params") ? JSON.parse(atob(localStorage.getItem("params"))) : {} ;
    const state = params.state;

    useEffect(() => {
        if (CRISP_WEBSITE_ID) {
            initCrispChat(state ? state : undefined);
        }
    }, []);

    return null;
};

export default CrispChat;
