import * as yup from "yup";

import { pattern } from "../context/regex";

export const MIN_LENGTH = 3;
export const PWD_MIN_LENGTH = 8;
export const PWD_MAX_LENGTH = 46;
export const CHAR_STANDARD_LENGTH = 31;
export const REACHED_UPPER_LIMIT_MSG = "Exceeded Character limit.";
export const EMAIL_MAX_LENGTH = 46;
export const IVR_TTS_MAX_LENGTH = 1000;
export const FIRSTNAME_MAX_LENGTH = 18;
export const LASTNAME_MAX_LENGTH = 44;
export const FULLNAME_MAX_LENGTH = 62;
export const CHAT_MESSAGE_MAX_LENGTH = 1000;
export const SMS_MAX_LENGTH = 1600;
export const MAX_350 = 350;
export const MAX_255 = 255;
export const MAX_DESC_LIMIT = 1600;

export const EMAIL_REGEX = /^(?:[^\s@]+@[^\s@]+\.[^\s@]+)?$/;
export const inputs = {
  required: yup.string().required("Required"),
  default: yup.string().max(CHAR_STANDARD_LENGTH, REACHED_UPPER_LIMIT_MSG),
  email: yup
    .string()
    .matches(EMAIL_REGEX, "Email must be a valid email")
    .email("Email must be a valid email")
    .max(EMAIL_MAX_LENGTH, `Shouldn't exceed ${EMAIL_MAX_LENGTH} characters`),
  textField: yup
    .string()
    .matches(pattern.isBlank, "This field Can't be blank")
    .max(CHAR_STANDARD_LENGTH, REACHED_UPPER_LIMIT_MSG),
  password: yup
    .string()
    .required("Password is required")
    .min(PWD_MIN_LENGTH, `Should contain atleast ${PWD_MIN_LENGTH} characters`)
    .max(PWD_MAX_LENGTH, `Shouldn't exceed ${PWD_MAX_LENGTH} characters`)
    .matches(pattern.hasSpecialLowerUpperNumeric, "Need one upper, one lower, one numeric and one special character"),
  fullname: yup
    .string()
    .trim()
    .required("Full Name is required")
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(LASTNAME_MAX_LENGTH, `Shouldn't exceed ${FULLNAME_MAX_LENGTH} characters`)
    .matches(pattern.alphabetic, "Should only contain alphabets"),
  firstName: yup
    .string()
    .trim()
    .required("Required")
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(FIRSTNAME_MAX_LENGTH, `Shouldn't exceed ${FIRSTNAME_MAX_LENGTH} characters`)
    .matches(pattern.alphabetic, "Should only contain alphabets"),
  lastName: yup
    .string()
    .trim()
    .required("Required")
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(LASTNAME_MAX_LENGTH, `Shouldn't exceed ${LASTNAME_MAX_LENGTH} characters`)
    .matches(pattern.alphabetic, "Should only contain alphabets"),
  genericText: yup.string().trim().max(CHAR_STANDARD_LENGTH, REACHED_UPPER_LIMIT_MSG),
  title: yup
    .string()
    .trim()
    .max(CHAR_STANDARD_LENGTH, `Shouldn't exceed ${CHAR_STANDARD_LENGTH} characters`),
  description: yup
    .string()
    .trim()
    .max(MAX_DESC_LIMIT, `Should exceed ${MAX_DESC_LIMIT} characters`),
  number: yup.string(),
  select: yup.string().required("Required"),
};
