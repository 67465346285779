import styled, { css } from "styled-components";

// need to workaround explicit any
const StyledComponent = styled.p`
  font-size:14px;
  color: ${({ theme, color }) => (color ? `${color}` : theme.colors?.text.t5)};
  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight}px;
    `};

  .capitalize {
    text-transform: capitalize;
  }
  margin-bottom: 0px;
  margin-top: 0px;
  text-align:left;
`;

const Text = (props) => {
  const { as = "p", className, children, ...rest } = props;
  return (
    <StyledComponent
      as={as}
      className={className}
      {...rest}
    >
      {children}
    </StyledComponent>
  );
};

export default Text;
