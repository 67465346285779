// KrispCall Component
import { useEffect, useState } from "react";

import { Form } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom";

import { Form as CustomForm, useForm } from "src/krispcall/components/organisms/form";
import { CaptchaRule } from "src/common/modal/CaptchaModal";
import { CaptchaProvider } from "src/krispcall/utils/CaptchaProvider";
import {
  CaptchaModalCustom,
  RecapthcaV2
} from "src/krispcall/components/organisms";
import { GoogleOneTapLoginVirtualButton } from "src/auth/GoogleLoginButton";
import { createMarkup } from "src/krispcall/utils/markup";
import { BACK_LINKS } from "src/krispcall/constants/hyper-links";
import {
  InputField,
  PasswordField
} from "src/krispcall/components/molecules/fields/v3";
import Button from "src/krispcall/components/atoms/button/v3/Button";
import { useGetRecaptchaV3Token } from "src/krispcall/components/organisms/recaptcha/useGetRecaptchaV3Token";

import * as ProviderButtonCustom from "src/krispcall/components/organisms/provider-button/ProviderButtonCustom";
import * as UserBackend from "src/backend/UserBackend";
import * as Util from "src/auth/Util";

import { CAPTCHA_SITE_KEY } from "../../../../constants/KEYS";
import { SignupPageWrapper } from "../Styles";
import { schema } from "../schema";

export const BusinessForm = ({
  state,
  Setting,
  application,
  updateFormValues,
  props,
}) => {
  const [captchaVerified, setCaptchaVerified] = useState(!CAPTCHA_SITE_KEY);
  const [buttonLoading, setButtonLoading] = useState(false);
  const provider = CaptchaProvider(application);

  const method = "signup";
  const handleEmailVerification = async(values) => {
    updateFormValues("email", values.email);
    updateFormValues("password", values.password);
    updateFormValues("name", values.name);
    setButtonLoading(true);
    const captchaToken = await state.captchaType === "reCAPTCHA v3-Standard" ?
      await useGetRecaptchaV3Token(state.captchaSiteKey) :
      state.captchaToken;
    const onButtonClickArgs = [
      values.email,
      "email",
      Setting.getApplicationName(application),
    ];
    UserBackend.sendCode(
      state.captchaType !== "" ? state.captchaType : "none",
      captchaToken,
      state.clientSecret,
      method,
      "",
      ...onButtonClickArgs
    ).then((res) => {
      setButtonLoading(false);
      setCaptchaVerified(false);
      if (!res && state.captchaType !== "reCAPTCHA v3-Standard" && provider !== null) {
        window.grecaptcha.reset();
      }

      if (res) {
        updateFormValues("showBusinessForm", false);
        updateFormValues("showOtpForm", true);
        updateFormValues("openCaptchaModal", false);
        updateFormValues("captchaToken", "");
        updateFormValues("clientSecret", "");
      }
    });
  };

  // if captcha token avaible and captcha type is default then send captcha information in api
  useEffect(() => {
    if (state.captchaType === "Default") {
      handleEmailVerification();
    }
  }, [state, state.captchaToken]);

  function renderCaptcha() {
    if (state.enableCaptchaModal === CaptchaRule.None) {
      return null;
    }
    if (provider?.type === "Default") {
      return (
        <CaptchaModalCustom
          owner={provider?.owner}
          name={provider?.name}
          visible={state.openCaptchaModal}
          onOk={(captchaType, captchaToken, clientSecret) => {
            updateFormValues("captchaType", captchaType);
            updateFormValues("captchaToken", captchaToken);
            updateFormValues("clientSecret", clientSecret);
            () => updateFormValues("openCaptchaModal", false);
            setCaptchaVerified(true);
          }}
          onCancel={() => updateFormValues("openCaptchaModal", false)}
          isCurrentProvider={true}
        />
      );
    } else if (provider?.type === "reCAPTCHA v3-Standard" || provider?.type === "reCAPTCHA") {
      return (
        <div>
          <RecapthcaV2
            owner={provider?.owner}
            name={provider?.name}
            setCaptchaVerified={setCaptchaVerified}
            onOk={(captchaType, captchaToken, clientSecret, captchaSiteKey) => {
              updateFormValues("captchaType", captchaType);
              updateFormValues("captchaToken", captchaToken);
              updateFormValues("clientSecret", clientSecret);
              updateFormValues("captchaSiteKey", captchaSiteKey);
            }}
            isCurrentProvider={true}
          />
        </div>
      );
    }
  }

  function isProviderVisible(providerItem) {
    return Setting.isProviderVisibleForSignUp(providerItem);
  }

  function renderOtherFormProvider(application) {
    if (Setting.inIframe()) {
      return null;
    }

    for (const providerConf of application.providers) {
      if (
        providerConf.provider?.type === "Google" &&
        providerConf.rule === "OneTap" &&
        props.preview !== "auto"
      ) {
        return (
          <GoogleOneTapLoginVirtualButton
            application={application}
            providerConf={providerConf}
          />
        );
      }
    }

    return null;
  }

  function renderProviders(application, signinItem) {
    if (signinItem.name === "Providers") {
      const showForm =
        Setting.isPasswordEnabled(application) ||
        Setting.isCodeSigninEnabled(application) ||
        Setting.isWebAuthnEnabled(application) ||
        Setting.isLdapEnabled(application);
      if (signinItem.rule === "None" || signinItem.rule === "") {
        signinItem.rule = showForm ? "small" : "big";
      }

      return (
        <div className="w-full">
          <div dangerouslySetInnerHTML={createMarkup(signinItem.label)} />
          <Form.Item className="w-full">
            {application.providers
              .filter((providerItem) => isProviderVisible(providerItem))
              .map((providerItem) => {
                return ProviderButtonCustom.renderProviderLogoCustom(
                  providerItem.provider,
                  application,
                  "24px",
                  "0",
                  signinItem.rule,
                  props.location
                );
              })}
            {renderOtherFormProvider(application)}
          </Form.Item>
        </div>
      );
    }
  }
  const oAuthParams = localStorage.getItem("params")
    ? JSON.parse(atob(localStorage.getItem("params")))
    : Util.getOAuthGetParameters();

  const FORM_ID = "signup-form";
  const form = useForm({
    schema,
    mode: "onChange",
  });

  const {
    formState: { isValid },
  } = form;

  const handleDisabled = (provider?.type === "Default" || provider?.type === "reCAPTCHA v3-Standard" || provider?.type === undefined)
    ? false : !(isValid && captchaVerified);

  return (
    <SignupPageWrapper>
      <span className="signup-page-title">Start your free trial</span>
      <div className="signup-sub-title">
        Already have an account?{" "}
        <Link to={`/login/oauth/authorize?client_id=${oAuthParams?.clientId}&response_type=${oAuthParams?.responseType}&redirect_uri=${oAuthParams?.redirectUri}&scope=${oAuthParams?.scope}&state=${oAuthParams?.state}&code_challenge=${oAuthParams?.codeChallenge}&code_challenge_method=${oAuthParams?.challengeMethod}`} className="signup-sub-title-link">Sign In</Link>
      </div>
      <CustomForm
        form={form}
        id={FORM_ID}
        onSubmit={handleEmailVerification}
        fieldSetClassName="flex flex-col gap-4"
      >
        <InputField
          label="Full Name"
          name="name"
          id="name"
          autoFocus
        />

        <InputField label="Email address" name="email" id="email" />

        <PasswordField
          label="Set strong password"
          name="password"
          id="password"
          enableStrengthChecker={true}
        />

        {
          renderCaptcha(application)
        }

        <Button disabled={handleDisabled} loading={buttonLoading} form={FORM_ID} htmlType="submit" variant="primary">Create Krispcall Account</Button>
      </CustomForm>
      <div className="signup-agreement mb-2 text-text-400">By continuing, you’re agreeing to KrispCall’s
        <a target="_blank" href={BACK_LINKS.TOS} rel="noopener noreferrer" className="signup-agreement-link"> Terms of Service</a>,
        <a target="_blank" href={BACK_LINKS.POLICY} rel="noopener noreferrer" className="signup-agreement-link">Privacy Policy</a> and
        <a target="_blank" href={BACK_LINKS.COOKIES_POLICIY} rel="noopener noreferrer" className="signup-agreement-link"> Cookie Policy</a>.
      </div>
      <div className="or-section">
        <div /> <span>OR</span> <div />
      </div>

      {
            application.signinItems?.map(signinItem => renderProviders(application, signinItem))
      }
    </SignupPageWrapper>
  );
};
