import styled from "styled-components";

export const SignupPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 320px;

    .signup-page-title {
        color: ${({ theme }) => theme.colors.text.t6};
        font-size: 24px;
        font-weight: 500;
        line-height: 150%;
    }

    .signup-sub-title {
        font-size: 15px;
        font-style: normal;
        line-height: 160%;
        margin-bottom: 40px;
        color: ${({ theme }) => theme.colors.text.t4};
    }

    .signup-sub-title-link {
        color: ${({ theme }) => theme.colors.link.l3};
        cursor: pointer;
    }

    .signup-agreement {
        text-align: center;
        font-size: 13px;
        line-height: 140%;
        margin-top: 16px;
    }

    .signup-agreement-link {
        color: ${({ theme }) => theme.colors.link.l3};
    }

    .side-image-container {
        height: 60vh;
    }

    .left-model {
        height:  -webkit-fill-available;

        img {
            max-height: 100%!important;
        }
    }
`;

export const SignupLeftWrapper = styled.div`
    width: 530px;

    .panel-logo {
        width: auto;
        height: 32px;
        margin-bottom: 80px;
    }

    img {
        max-height: 400px;
    }

    .signup-bottom-title {
        color: ${({ theme }) => theme.colors.text.t6};
        font-size: 32px;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: -0.64px;
    }

    .signup-bottom-sub-title {
        color: ${({ theme }) => theme.colors.text.t3};
        font-size: 15px;
        line-height: 160%;
    }

    .side-image-container {
        height: 60vh;
    }

    .left-model {
        height:  -webkit-fill-available;

        img {
            max-height: 100%!important;
        }
    }
`;

export const SignupEmailVerificationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 320px;

    .signup-icon-circle {
        width: 80px;
        height: 80px;
        flex-shrink: 0;
        border-radius: 40px;
        background: ${({ theme }) => theme.colors.background.b2};
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 48px;
            height: 48px;
        }
    }

    .signup-verification-title {
        color: ${({ theme }) => theme.colors.text.t6};
        font-size: 24px;
        font-weight: 500;
        line-height: 150%;
    }

    .signup-verification-sub-title {
        color: ${({ theme }) => theme.colors.text.t4};
        font-size: 15px;
        line-height: 160%;
    }
`;
