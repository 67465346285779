// Krispcall
import React from "react";

import { withRouter } from "react-router-dom";
import { Result } from "antd";
import i18next from "i18next";

import { CaptchaRule } from "src/common/modal/CaptchaModal";
import { getCaptchaProviderItems } from "src/krispcall/utils/CaptchaProvider";
import CustomGithubCorner from "src/common/CustomGithubCorner";
import { filterByProperty, getValuesByKey } from "src/krispcall/utils";
import { BusinessForm } from "src/krispcall/components/v3-pages/signup/common/BusinessForm";
import { CustomThemeContextProvider } from "src/krispcall/context/CustomThemeContext";
import { expireCookie } from "src/krispcall/utils/Setting";
import { createMarkup } from "src/krispcall/utils/markup";

import * as InvitationBackend from "src/backend/InvitationBackend";
import * as Setting from "src/Setting";
import * as ApplicationBackend from "src/backend/ApplicationBackend";

import { SignupLeftWrapper } from "./Styles";
import { OtpForm } from "./common/OtpForm";
import { authConfig } from "../../../../auth/Auth";
import { Button } from "../../atoms";
import { CrispChat } from "../../organisms";

import * as AuthBackend from "../../../../auth/AuthBackend";
import * as Util from "../../../../auth/Util";

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class SignupPageCustom extends React.Component {
  constructor(props) {
    super(props);
    this.onFinishRef = this.onFinish.bind(this);
    this.handleSubmitRef = this.handleSubmit.bind(this);

    this.state = {
      classes: props,
      applicationName:
        props.applicationName ?? props.match?.params?.applicationName ?? null,
      email: "",
      phone: "",
      password: "",
      countryCode: "",
      emailCode: "",
      phoneCode: "",
      validEmail: false,
      validPhone: false,
      region: "",
      isTermsOfUseVisible: false,
      termsOfUseContent: "",
      // krispcall
      name: "",
      showBusinessForm: true,
      showOtpForm: false,
      enableCaptchaModal: CaptchaRule.None,
      openCaptchaModal: false,
      captchaToken: "",
      captchaType: "",
      clientSecret: "",
      captchaSiteKey: "",
      formValues: {},
      submitLoading: false,
    };

    this.form = React.createRef();
  }

  componentDidMount() {
    const oAuthParams = Util.getOAuthGetParameters();
    if (oAuthParams) {
      localStorage.setItem("params", btoa(JSON.stringify(oAuthParams)));
    }

    if (oAuthParams !== null) {
      const signinUrl = window.location.pathname.replace(
        "/signup/oauth/authorize",
        "/login/oauth/authorize"
      );
      sessionStorage.setItem("signinUrl", signinUrl + window.location.search);
    }

    if (this.getApplicationObj() === undefined) {
      if (this.state.applicationName !== null) {
        this.getApplication(this.state.applicationName);

        const sp = new URLSearchParams(window.location.search);
        if (sp.has("invitationCode")) {
          const invitationCode = sp.get("invitationCode");
          this.setState({ invitationCode: invitationCode });
          if (invitationCode !== "") {
            this.getInvitationCodeInfo(
              invitationCode,
              "admin/" + this.state.applicationName
            );
          }
        }
      } else if (oAuthParams !== null) {
        this.getApplicationLogin(oAuthParams);
      } else {
        Setting.showMessage(
          "error",
          `Unknown application name: ${this.state.applicationName}`
        );
        this.onUpdateApplication(null);
      }
    }
    if (this.getApplicationObj()) {
      this.onUpdateApplication(this.getApplicationObj());
    }
  }

  getApplication(applicationName) {
    if (applicationName === undefined) {
      return;
    }

    ApplicationBackend.getApplication("admin", applicationName).then((res) => {
      const oAuthParams = Util.getOAuthGetParameters();
      if (!oAuthParams && !localStorage.getItem("params")) {
        Setting.showMessage("error", "Sesion Expired! Couldn't find Application!");
        window.location.href = res.data.homepageUrl;
      }
      if (res.status === "error") {
        Setting.showMessage("error", res.msg);
        return;
      }
      this.onUpdateApplication(res.data);
    });
  }

  login(application) {
    this.setState({ ["submitLoading"]: true });
    const params = JSON.parse(atob(localStorage.getItem("params")));
    const concatChar = params?.redirectUri?.includes("?") ? "&" : "?";
    const values = {};
    values["application"] = application.name;
    values["type"] = params?.responseType;

    AuthBackend.login(values, params)
      .then((res) => {
        this.setState({ ["submitLoading"]: false });
        const loginHandler = (res) => {
          const redirectUrl = `${params.redirectUri}${concatChar}code=${res.data}&state=${params.state}`;
          sessionStorage.clear();
          localStorage.clear();
          expireCookie("krispcall_session_id", "/");
          window.location.href = redirectUrl;
        };
        if (res.status === "ok") {
          loginHandler(res);
        }
      });
  }
  getApplicationLogin(oAuthParams) {
    AuthBackend.getApplicationLogin(oAuthParams).then((res) => {
      if (res.status === "ok") {
        const application = res.data;
        this.onUpdateApplication(application);
      } else {
        this.onUpdateApplication(null);
        this.setState({
          msg: res.msg,
        });
      }
    });
  }

  getInvitationCodeInfo(invitationCode, application) {
    InvitationBackend.getInvitationCodeInfo(invitationCode, application).then(
      (res) => {
        if (res.status === "error") {
          Setting.showMessage("error", res.msg);
          return;
        }
        this.setState({ invitation: res.data });
      }
    );
  }

  getResultPath(application, signupParams) {
    if (signupParams?.plan && signupParams?.pricing) {
      // the prompt page needs the user to be signed in, so for paid-user sign up, just go to buy-plan page
      return `/buy-plan/${application.organization}/${signupParams?.pricing}?user=${signupParams.username}&plan=${signupParams.plan}`;
    }
    if (authConfig.appName === application.name) {
      return "/result";
    } else {
      if (Setting.hasPromptPage(application)) {
        return `/prompt/${application.name}`;
      } else {
        const params = JSON.parse(atob(localStorage.getItem("params")));
        return `/login/oauth/authorize?client_id=${params.clientId}&response_type=${params.responseType}&redirect_uri=${params.redirectUri}&scope=${params.scope}&state=${params.state}code_challenge=${params.codeChallenge}&code_challenge_method=${params.challengeMethod}`;
        // return `/result/${application.name}`; Commented casdoor default page
      }
    }
  }

  getApplicationObj() {
    return this.props.application;
  }

  onUpdateAccount(account) {
    this.props.onUpdateAccount(account);
  }

  onUpdateApplication(application) {
    this.props.onUpdateApplication(application);
    const captchaProviderItems = getCaptchaProviderItems(application);
    if (captchaProviderItems) {
      if (captchaProviderItems.some(providerItem => providerItem.rule === "Always")) {
        this.setState({enableCaptchaModal: CaptchaRule.Always});
      } else if (captchaProviderItems.some(providerItem => providerItem.rule === "Dynamic")) {
        this.setState({enableCaptchaModal: CaptchaRule.Dynamic});
      } else {
        this.setState({enableCaptchaModal: CaptchaRule.None});
      }
    }
  }

  parseOffset(offset) {
    if (
      offset === 2 ||
      offset === 4 ||
      Setting.inIframe() ||
      Setting.isMobile()
    ) {
      return "0 auto";
    }
    if (offset === 1) {
      return "0 10%";
    }
    if (offset === 3) {
      return "0 60%";
    }
  }

  async onFinish(values) {
    const application = this.getApplicationObj();
    this.setState({ ["submitLoading"]: true });
    const params = new URLSearchParams(window.location.search);
    values.plan = params.get("plan");
    values.pricing = params.get("pricing");
    // setting up captcha fields
    values.captchaType = this.state.captchaType;
    values.captchaToken = this.state.captchaToken;
    values.clientSecret = this.state.clientSecret;
    const additionalInfo = {
      organization: application.organization,
      application: application.name,
      email: this.state.email,
      emailCode: values.emailCode,
      name: this.state.name,
      password: this.state.password,
    };
    const response = await AuthBackend.signup({ ...values, ...additionalInfo }).then((res) => {
      this.setState({ ["submitLoading"]: false });
      if (res.status === "ok") {
        // the user's id will be returned by `signup()`, if user signup by phone, the `username` in `values` is undefined.
        values.username = res.data.split("/")[1];
        if (
          Setting.hasPromptPage(application) &&
          (!values.plan || !values.pricing)
        ) {
          AuthBackend.getAccount("").then((res) => {
            let account = null;
            if (res.status === "ok") {
              account = res.data;
              account.organization = res.data2;

              this.onUpdateAccount(account);
              // incase we need to revert
              // Setting.goToLinkSoft(
              //   this,
              //   this.getResultPath(application, values)
              // );
              const params = JSON.parse(atob(localStorage.getItem("params")));
              this.getApplicationLogin(params);
            } else {
              Setting.showMessage(
                "error",
                `${res.msg}`
              );
            }
          });
        } else {
          // hard reload to load session
          // Setting.goToLinkSoft(this, this.getResultPath(application, values));
        }
      } else {
        Setting.showMessage("error", res.msg);
      }
      return res;
    });
    if (response && response?.status === "ok") {
          this.login(application);
    }
  }

  onFinishFailed(values, errorFields, outOfDate) {
    this.form.current.scrollToField(errorFields[0].name);
  }

  isProviderVisible(providerItem) {
    return Setting.isProviderVisibleForSignUp(providerItem);
  }

  handleSubmit(values) {
    if (this.state.captchaType === "Default") {
      this.setState({ ["openCaptchaModal"]: true });
      this.setState({["formValues"]: values});
    } else {
      this.onFinish(values);
    }
  }

  renderForm(application) {
    if (!application.enableSignUp) {
      return (
        <Result
          status="error"
          title={i18next.t("application:Sign Up Error")}
          subTitle={i18next.t(
            "application:The application does not allow to sign up new account"
          )}
          extra={[
            <Button
              type="primary"
              key="signin"
              onClick={() =>
                Setting.redirectToLoginPage(application, this.props.history)
              }
            >
              {i18next.t("login:Sign In")}
            </Button>,
          ]}d
        ></Result>
      );
    }
    if (this.state.invitation !== undefined) {
      if (this.state.invitation.username !== "") {
        this.form.current?.setFieldValue(
          "username",
          this.state.invitation.username
        );
      }
      if (this.state.invitation.email !== "") {
        this.form.current?.setFieldValue("email", this.state.invitation.email);
      }
      if (this.state.invitation.phone !== "") {
        this.form.current?.setFieldValue("phone", this.state.invitation.phone);
      }
      if (this.state.invitationCode !== "") {
        this.form.current?.setFieldValue(
          "invitationCode",
          this.state.invitationCode
        );
      }
    }

    const signUpItemsArrayByName = getValuesByKey(
      application.signupItems,
      "name"
    );
    const isEmailFieldVisibleInCasdoor =
      signUpItemsArrayByName.includes("Email");

    const EmailFieldProps = filterByProperty(
      application?.signupItems,
      "name",
      "Email"
    )?.[0];
    const updateFormValues = (field, value) => {
      this.setState({ [field]: value });
    };

    return (
      <>
        {isEmailFieldVisibleInCasdoor &&
          this.state.showBusinessForm && (
            <BusinessForm
              signupItem={EmailFieldProps}
              required={EmailFieldProps.required}
              state={this.state}
              Setting={Setting}
              application={application}
              updateFormValues={updateFormValues}
              props={this.props}
            />
          )}
        {isEmailFieldVisibleInCasdoor && this.state.showOtpForm && (
          <OtpForm
            email={this.state.email}
            application={application}
            signupItem={EmailFieldProps}
            required={EmailFieldProps.required}
            updateFormValues={updateFormValues}
            state={this.state}
            handleSubmit={this.handleSubmitRef}
          />
        )}

      </>
    );
  }

  render() {
    const application = this.getApplicationObj();
    if (application === undefined || application === null) {
      return null;
    }

    if (application.signupHtml !== "") {
      return (
        <div dangerouslySetInnerHTML={createMarkup(application.signupHtml)} />
      );
    }

    return (
      <CustomThemeContextProvider>
        <CustomGithubCorner />
        <div
          className="login-content"
          style={{
            margin:
              this.props.preview ?? this.parseOffset(application.formOffset),
          }}
        >
          {Setting.inIframe() || Setting.isMobile() ? null : (
            <div dangerouslySetInnerHTML={createMarkup(application.formCss)} />
          )}
          {Setting.inIframe() || !Setting.isMobile() ? null : (
            <div
              dangerouslySetInnerHTML={createMarkup(application.formCssMobile)}
            />
          )}
          <div className="login-panel">
            <div
              className="side-image"
            >
              <SignupLeftWrapper>
                {Setting.renderLogo(application)}
                <div
                  className="side-image-container"
                  dangerouslySetInnerHTML={createMarkup(application.formSideHtml)}
                />
                <div className="flex flex-col mt-10">
                  <span className="signup-bottom-title">Connect with clients globally</span>
                  <span className="signup-bottom-sub-title">Join the future of cloud telephony for businesses</span>
                </div>
              </SignupLeftWrapper>

            </div>
            <div className="login-form-container">
              {/* incase we need it later */}
              {/* <div className="w-[413px]">
                {Setting.renderHelmet(application)}
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  {Setting.renderLogo(application)}
                  <LanguageSelect
                    languages={application.organizationObj.languages}
                    // style={{ top: "55px", right: "5px", position: "absolute"}}
                  />
                </div>
              </div> */}
              {this.renderForm(application)}
            </div>
          </div>
        </div>
        <CrispChat />
      </CustomThemeContextProvider>
    );
  }
}

export default withRouter(SignupPageCustom);
