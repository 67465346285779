import * as yup from "yup";

import { inputs } from "src/krispcall/schema-validation";

const PWD_MIN_LENGTH = 8;

export const schema = yup.object().shape({
  username: inputs.email,
  password: yup.string()
    .required("Password is required")
    .min(PWD_MIN_LENGTH, `Should contain atleast ${PWD_MIN_LENGTH} characters`)});
