import { Input } from "antd";
import styled, { css } from "styled-components";

const InputFieldCSS = css`
  &.ant-input,
  &.ant-input-affix-wrapper {
    :not(.ant-input-disabled) {
      background-color: ${({ theme }) => theme.colors.error.e1};
    }
  }
`;

const StyledInput = styled(Input)`
  ${props => props?.status === "error" && InputFieldCSS}
`;

export default StyledInput;
