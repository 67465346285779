import { ConfigProvider } from "antd";
import { useTheme } from "styled-components";

import useButtonConfig from "./useButtonConfig";
import { StyledButton } from "./styles";

const Button = (props) => {
  const theme = useTheme();
  const { variant = "secondary", ...rest } = props;

  const mapClassName = {
    secondary: "border border-stroke-100 hover:border-stroke-200",
    "secondary-info": "border border-link-300 hover:border-link-400",
    "secondary-success": "border border-success-300 hover:border-success-400",
    "secondary-warning": "border border-warning-300 hover:border-warning-400",
  };

  const { config } = useButtonConfig();

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            primaryShadow: "none",
            borderColorDisabled: theme.colors.background.b3,
            colorBgContainerDisabled: theme.colors.background.b3,
            // eslint-disable-next-line react/jsx-props-no-spreading
            ...config[variant],
          },
        },
      }}
    >
      <StyledButton
        type={"primary" || rest?.type}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        classNames={
          {
            // todo: use this for icon size styling after tailwind is updated
            // icon: '[&>svg]:w-5 [&>svg]:h-5',
          }
        }
        className={`flex justify-center items-center ${mapClassName[variant] ?? ""} ${
          rest?.className ?? ""
        }`}
      />
    </ConfigProvider>
  );
};

Button.defaultProps = {
  variant: "secondary",
};

export default Button;
