import {Form} from "antd";

import { Button } from "src/krispcall/components/atoms";
import { OtpInputField } from "src/krispcall/components/molecules/fields";

import * as Setting from "src/Setting";

export const MfaVerifyTotpFormCustom = ({mfaProps, onFinish, state, setAuthOtpCode, updateFormValues, loading}) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      onFinish={() => {
          onFinish({passcode: state?.code});
        }}
      className={Setting.isMobile() ? "w-[300px]" : "w-[400px]"}
      size="large"
    >
      {/* we will not render secret or qr from here. this section is moved to user>>profile>>edit. function removed */}
      {/* {renderSecret()} */}

      <OtpInputField
        value={state.code}
        setEmailOtpCode={setAuthOtpCode}
        containerStyle={{ marginBottom: "24px" }} />

      <Form.Item>
        <Button
          loading={loading}
          block
          type="primary"
          htmlType="submit"
          disabled={state.code?.length < 6}
        >
          Continue
        </Button>
      </Form.Item>

    </Form>
  );
};

export default MfaVerifyTotpFormCustom;
