import { AuthenticatorApp, SmsIcon, VerificationIcon } from "src/krispcall/assets";

const VerificationTypeCard = ({item, changeMfaType}) => {
    return (
        <div className="verification-box cursor-pointer" onClick={() => changeMfaType(item.mfaType)} >
            {
                item.mfaType === "sms" &&
                <img src={SmsIcon} />
            }
            {
                item.mfaType === "email" &&
                <img src={VerificationIcon} />
            }
            {
                item.mfaType === "app" &&
                <img src={AuthenticatorApp} />
            }
            <div className="flex flex-col">
                {
                  item.mfaType === "sms" &&
                  <>
                    <span className="verification-box-title">Phone Number</span>
                    <span>SMS me the code at {item.secret}</span>
                  </>
                }
                {
                  item.mfaType === "email" &&
                  <>
                    <span className="verification-box-title">Email</span>
                    <span>Please enter the 6 digit code sent to your email for verification.</span>
                  </>
                }
                {
                  item.mfaType === "app" &&
                  <>
                    <span className="verification-box-title">Authenticator App</span>
                    <span>Enter the code you see on the app</span>
                  </>
                }
            </div>
        </div>
    );
};
export default VerificationTypeCard;
