// Copied from SendCodeInput for modifications
import React, { useEffect, useState } from "react";

import {Button, Form} from "antd";
import i18next from "i18next";

import { OtpInputField } from "src/krispcall/components/molecules/fields";
import { Spinner, Text } from "src/krispcall/components/atoms";
import { CaptchaModal } from "src/common/modal/CaptchaModal";

import * as UserBackend from "src/backend/UserBackend";

export const SendCodeInputCustom = ({value, disabled, textBefore, onChange, onButtonClickArgs, application, method, countryCode, state, setEmailOtpCode}) => {
  const [buttonLeftTime, setButtonLeftTime] = React.useState(0);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [resend, setResend] = useState(undefined);
  const [visible, setVisible] = useState(false);
  let timer = undefined;

  const handleCountDown = (leftTime = 60) => {
    let leftTimeSecond = leftTime;
    setButtonLeftTime(leftTimeSecond);
    const countDown = () => {
      leftTimeSecond--;
      setButtonLeftTime(leftTimeSecond);
      if (leftTimeSecond === 0) {
        return;
      }
      timer = setTimeout(countDown, 1000);
    };
    timer = setTimeout(countDown, 1000);
  };

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, []);

  function handleOk(
    captchaType, captchaToken, clintSecret
  ) {
    setButtonLoading(true);

    UserBackend.sendCode(
      captchaType ? captchaType : "none", captchaToken, clintSecret,
      method, countryCode, ...onButtonClickArgs).then(res => {
      setVisible(false);
      setButtonLoading(false);
      handleCountDown(60);
    });
  }

  useEffect(() => {
    if (resend === undefined) {
      handleOk();
    }
  }, [resend]);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      <OtpInputField
        value={state.code}
        setEmailOtpCode={setEmailOtpCode}
        containerStyle={{ marginBottom: "24px" }} />

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          disabled={state.code.length < 6}
          loading={state.buttonLoading && state.code.length > 5}
        >
          {i18next.t("forget:Continue")}
        </Button>
      </Form.Item>

      <Text className="mt-6 flex flex-row">
        Didn’t receive the code?&nbsp;{buttonLoading ? <><Spinner />&nbsp;Sending...</> : ""}&nbsp;
        {buttonLeftTime > 0 ? <>Resend in&nbsp;<b> {buttonLeftTime} sec</b> </> : (!buttonLoading ? <span className="resend-otp" onClick={() => {setResend(true); setVisible(true);}}>&nbsp;{i18next.t("code:Send Code")}</span> : "")}
      </Text>
      <Text className="mt-6">
        Trouble verifying?
      </Text>

      <CaptchaModal
          owner={application.owner}
          name={application.name}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          isCurrentProvider={false}
        />

    </React.Fragment>
  );
};
