import styled from "styled-components";

export const OtpInputWrapper = styled.div`
  max-width: 364px;
  .otp-container {
    justify-content: space-between;
    input.otp-input {
      min-width: 44px;
      height: 44px;
      background: ${({ theme }) => theme.colors.background.b1};
      border: 1px solid ${({ theme }) => theme.colors.stroke.s1};
      border-radius: 10px;
      outline: none;
      :focus {
        outline: 1px solid ${({ theme }) => theme.colors.primary.p3};
      }
    }
    .otp-separator {
      margin: 0 -8px;
    }
    span:not(:nth-child(6)) {
      display: none;
    }
  }
`;
