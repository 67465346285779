import { darkColors, lightColors } from "./colors";
import text from "./text";

export const lightTheme = {
  name: "light theme",
  colors: lightColors,
  typography: text,
};

export const darkTheme = {
  name: "dark theme",
  colors: darkColors,
  typography: text,
};
