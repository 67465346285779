import React from "react";

import styled from "styled-components";

const SpinIcon = styled.div`
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid var(--primary-300); /* Blue */
  border-radius: 50%;
  width: 18px;
  height: ${props => props.height || "18px"};
  ${props => props.style}
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const Spinner = (props) => {
  const { height, style } = props;
  return (
    <SpinIcon height={height} style={style} />
  );
};

export default React.memo(Spinner);
