import { createGlobalStyle } from "styled-components";

import "./index.css";
import { antPopover, antProgress } from "./antd";
import { lightColors } from "./colors";

const GlobalStyle = createGlobalStyle`
  ${antPopover}
  ${antProgress}

  /* custom Scrollbar  */
  * {
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: ${({ theme }) => theme.colors.background.b1};
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 4px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${lightColors.primary.p2};
        border-radius: 8px;
    }

    /* for mozilla */
    scrollbar-width: thin;
    scrollbar-color: ${lightColors.primary.p2} white;
  }

  // react-scrollbar library
  .ps__rail-y:hover > .ps__thumb-y{
    background-color: ${lightColors.primary.p2};
  }
  .ps__thumb-y {
    background-color: ${lightColors.primary.p2};
  }
  .ps .ps__rail-y:hover{
    background-color:  ${lightColors.primary.p1};
  }

`;

export default GlobalStyle;
