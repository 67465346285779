import styled from "styled-components";

const Wrapper = styled.h5`
    color: #333036;
    font-weight:450;
    margin-bottom:6px;
    font-size:14px
`;

const InputLabel = ({ children }) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
};

export default InputLabel;
