// function to resolve zod schema we provide
import { yupResolver } from "@hookform/resolvers/yup";

import { ErrorV3 } from "../../atoms";

// We will fully type `<Form />` component by providing component props and fwding // those

import {
  // we import useForm hook as useHookForm
  useForm as useHookForm,
  // context provider for our form
  FormProvider,
  // hook that would return errors in current instance of form
  useFormContext
} from "react-hook-form";

// We provide additional option that would be our zod schema.
export const useForm = ({
  schema,
  ...formConfig
}) => {
  return useHookForm({
    ...formConfig,
    resolver: yupResolver(schema),
  });
};

// we omit the native `onSubmit` event in favor of `SubmitHandler` event
// the beauty of this is, the values returned by the submit handler are fully typed
export const Form = ({
  form,
  onSubmit,
  children,
  fieldSetClassName,
  ...props
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      {/* the `form` passed here is return value of useForm() hook */}

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <form onSubmit={form.handleSubmit(onSubmit)} {...props}>
        {/* We disable form inputs when we are submitting the form!! A tiny detail
              that is missed a lot of times */}
        <fieldset className={fieldSetClassName} disabled={form.formState.isSubmitting}>
          {children}
        </fieldset>
      </form>
    </FormProvider>
  );
};
Form.defaultProps = {
  fieldSetClassName: "",
};

function getFieldArrayError(errors, inputFieldName) {
  const hasFieidArrayError = inputFieldName?.split(".")?.length > 1;
  if (!hasFieidArrayError) return false;

  const errorMessage = inputFieldName?.replace(/\[(\d+)\]/g, ".$1").split(".");
  /* Convert brackets in name to dot & split
   * e.g.
   * `secondaryNumber[0]` -> [secondaryNumber, 0]
   * `secondaryNumber[0].number` -> [secondaryNumber, 0, number]
   */
  const MAX_NAME_SPLIT_LENGTH = 3;
  const errorKey = errors?.[`${errorMessage[0]}`];
  const fieldIndex = parseFloat(errorMessage[1]);
  const inputField = errorKey?.[fieldIndex];

  if (errorMessage?.length < MAX_NAME_SPLIT_LENGTH) return inputField;

  const fieldArrayError = inputField?.[`${errorMessage[2]}`];
  return fieldArrayError;
}

export const useFieldError = ({ name }) => {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[name] || getFieldArrayError(errors, name);
  const hasError = !!error;

  const fieldStatus = hasError ? "error" : "";

  const errorElement =
    hasError && name ? <ErrorV3 message={error?.message} className="mt-2" /> : null;
  return { hasError, errorElement, fieldStatus };
};
