// this was required since, hooks cannot be directly used in class components
import { useEffect } from "react";

import { Form, useForm } from "src/krispcall/components/organisms/form";

import { schema } from "../schema";

const SigninFormWrapper = ({children, autoSignin, application, setState, onFinish}) => {
  const FORM_ID = "signin-form";
  const form = useForm({
    schema,
    mode: "onChange",
  });

  const {
    formState: { isValid },
  } = form;

  useEffect(() => {
    setState({ isValid: isValid });
  }, [isValid]);

  function handleSubmit(values) {
    values.autoSignin = autoSignin;
    values.application = application.name;
    values.organization = application.organization;
    onFinish(values);
  }

  return (
    <Form form={form} id={FORM_ID} onSubmit={handleSubmit} fieldSetClassName="flex flex-col gap-4 w-80">
      {children}
    </Form>
  );
};
export default SigninFormWrapper;
