import React, { useEffect } from "react";

import { EmailMfaType, SmsMfaType } from "src/auth/MfaSetupPage";
import { mfaAuth } from "src/auth/mfa/MfaVerifyForm";
import { EmailVerification, SmsIcon } from "src/krispcall/assets";
import { SendCodeInputCustom } from "src/krispcall/components/organisms/send-code-input/v3/SendCodeInputCustom";

import * as Setting from "src/Setting";

import { LoginVerificationWrapper } from "../Styles";
export const MfaVerifySmsForm = ({mfaProps, application, onFinish, method, user, setEmailOtpCode, state, updateFormValues}) => {
  const [dest, setDest] = React.useState("");
  useEffect(() => {
    if (method === mfaAuth) {
      setDest(mfaProps.secret);
      return;
    }
    if (mfaProps.mfaType === SmsMfaType) {
      setDest(user.phone);
      return;
    }

    if (mfaProps.mfaType === EmailMfaType) {
      setDest(user.email);
    }
  }, [mfaProps.mfaType]);

  const isEmail = () => {
    return mfaProps.mfaType === EmailMfaType;
  };

  const handleSubmit = async(emailCode) => {
    onFinish({passcode: emailCode});
  };

  return (
    <LoginVerificationWrapper>
      <div className="signup-icon-circle"><img src={isEmail() ? EmailVerification : SmsIcon} /></div>
        <div className="flex flex-col">
          <span className="login-verificaiton-title">2 step verification</span>
          {
            isEmail() ?
              <span className="login-verificaiton-subtitle">Please enter the 6 digit code sent to your email for verification.</span> :
              <span className="login-verificaiton-subtitle">Please enter the 6-digit code sent to your phone number {mfaProps?.secret}.</span>
          }
        </div>
        <SendCodeInputCustom
          name="passcode"
          application={application}
          state={state}
          setEmailOtpCode={setEmailOtpCode}
          method={method}
          handleSubmit={handleSubmit}
          onButtonClickArgs={[mfaProps.secret || dest, isEmail() ? "email" : "phone", Setting.getApplicationName(application)]}
        />
    </LoginVerificationWrapper>
  );
};

export default MfaVerifySmsForm;
