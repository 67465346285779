import { useEffect, useState } from "react";

import { Form, Progress } from "antd";

import {Text, InputLabel, Input} from "../../atoms";

const FieldComponent = (props) => {
    const style = {
        backgroundColor: "#F5F2F8",
        height: "44px",
        width: "100%",
    };

    switch (props.type) {
        case "text":
            return (<Input {...props} style={style} />);
        case "password":
            return (<Input.Password {...props} style={style} className="password-field" />);
        default:
            return (<Input {...props} style={style} />);
    }
};

const TopLabelInput = (props) => {
    const { name, label, placeholder, disabled, updateFormValues, type, rules, hasFeedback, dependencies, showStrength } = props;

    const [strength, setStrength] = useState("");
    const [percent, setPercent] = useState("0");
    const [color, setColor] = useState("#6E6681");
    const [info, setInfo] = useState("Strength Meter");

    const checkPasswordStrength = (value) => {
        if (value === "") {
            setStrength("");
            setPercent("0");
            setInfo("Strength Meter");
            setColor("#6E6681");
            return;
        }
        const length = value.length;
        const hasUpperCase = /[A-Z]/.test(value);
        const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value);
        if (length < 6 || !hasUpperCase || !hasSpecialChar) {
            setStrength("Weak");
        }
        if (length >= 6 && (hasUpperCase || hasSpecialChar)) {
            setStrength("Medium");
        }
        if (length >= 10 && hasUpperCase && hasSpecialChar) {
            setStrength("Strong");
        }
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        updateFormValues(name, value);
        if (type === "password" && showStrength) {
            checkPasswordStrength(value);
        }
    };

    useEffect(() => {
        if (strength === "Weak") {
            setPercent("30");
            setColor("#DB312B");
            setInfo("Weak");
        }

        if (strength === "Medium") {
            setPercent("60");
            setColor("#E07408");
            setInfo("Medium");
        }

        if (strength === "Strong") {
            setPercent("100");
            setColor("#4C9610");
            setInfo("Strong");
        }
    }, [strength]);

    return (
        <Form.Item
            name={name}
            rules={rules}
            initialValue={" "}
            hasFeedback={hasFeedback}
            dependencies={dependencies}
        >
            <div>
                <InputLabel>{label}</InputLabel>
                <FieldComponent
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={(e) => handlePasswordChange(e)}
                    type = {type || "text"}
                />
                {
                    type === "password" && showStrength &&
                    <>
                        <Progress id="password-progress" percent={percent} showInfo={false} strokeColor={color} />
                        <Text className={`text-xs -mt-2
                                        ${strength === "" ?
                                            "text-[#6E6681]" :
                                                (strength === "Weak" ?
                                                    "text-[#DB312B]" :
                                                strength === "Medium" ?
                                                    "text-[#E07408]" :
                                                    "text-[#4C9610]")}`}>
                            {info}
                        </Text>
                    </>
                }
            </div>
        </Form.Item>
    );
};
export default TopLabelInput;
