/* eslint-disable react/jsx-key */
import { Modal } from "antd";
import styled, { useTheme } from "styled-components";

import { Multiply } from "src/krispcall/components/atoms/icon";
import Button from "src/krispcall/components/atoms/button/v3/Button";

const StyledModal = styled(Modal)`
  .ant-modal-title {
    color: ${({ theme }) => theme.colors.text.t6};
    font-weight: 500;
  }
  .ant-modal-footer {
    .ant-btn span {
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

export const ActionModal = (props) => {
  const theme = useTheme();
  const { ...rest } = props;

  return (
    <StyledModal
      {...rest}
      centered={props?.centered ?? true}
      closeIcon={
        props?.closeIcon ?? (
          <Multiply
            className="p-1 bg-background-300 text-text-300 rounded"
            style={{ color: theme.colors.text.t3 }}
          />
        )
      }
      styles={{
        header: {
          padding: 16,
          margin: 0,
          boxShadow: `0px 1px 0px 0px ${theme.colors.stroke.s1}`,
        },

        footer: {
          display: "flex",
          justifyContent: "end",
          borderEndStartRadius: 10,
          borderEndEndRadius: 10,
          padding: 16,
          margin: 0,
          boxShadow: `0px -1px 0px 0px ${theme.colors.stroke.s1}`,
        },
        ...props?.styles,
      }}
      footer={
        props?.footer === null || props?.footer
          ? props?.footer
          : [
              <Button variant="secondary" {...props?.cancelButtonProps}>
                {props?.cancelText}
              </Button>,
              <Button variant="primary" {...props?.okButtonProps}>
                {props?.okText}
              </Button>,
            ]
      }
    />
  );
};
