import * as yup from "yup";

import { registerInputs } from "src/krispcall/schema-validation";

export const schema = yup.object().shape({
  name: registerInputs.name,
  email: registerInputs.email,
  password: registerInputs.password,
});

export const OtpSchema = yup.object().shape({
  emailCode: registerInputs.emailCode,
});
