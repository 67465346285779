import { createContext, useContext, useMemo } from "react";

import { ConfigProvider } from "antd";
import { ThemeProvider } from "styled-components";

import useThemeSwitcher from "src/krispcall/hooks/useThemeSwitcher";
import GlobalStyle from "src/krispcall/themes/globalStyles";

const CustomThemeContext = createContext(null);

const CustomThemeContextProvider = ({ children }) => {
  const {
    selectedTheme,
    setSelectedTheme,
    themeMode,
    toggleTheme,
    setThemeMode,
  } = useThemeSwitcher();

  const themeContextValues = useMemo(
    () => ({
      selectedTheme,
      setSelectedTheme,
      themeMode,
      toggleTheme,
      setThemeMode,
    }),
    [selectedTheme, setSelectedTheme, themeMode, toggleTheme, setThemeMode]
  );

  return (
    <CustomThemeContext.Provider value={themeContextValues}>
      <ThemeProvider theme={selectedTheme}>
        <GlobalStyle />
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: selectedTheme.colors.primary.p3,
              colorSuccess: selectedTheme.colors.success.s3,
              colorError: selectedTheme.colors.error.e3,
              colorInfo: selectedTheme.colors.link.l3,
              colorWarning: selectedTheme.colors.warning.w3,
              colorText: selectedTheme.colors.text.t4,
              colorTextDisabled: selectedTheme.colors.text.t2,
            },
            components: {
              Input: {
                controlHeightLG: 48,
                controlHeight: 44,
                controlHeightSM: 40,
                borderRadius: 10,
                borderRadiusSM: 10,
                paddingInline: 14,
                paddingInlineSM: 12,
                colorBgContainer: selectedTheme.colors.background.b1,
                colorBorder: selectedTheme.colors.stroke.s1,
                activeShadow: `0 0 0 3px ${selectedTheme.colors.primary.p1}`,
                activeBg: selectedTheme.colors.background.b1,
                hoverBorderColor: selectedTheme.colors.stroke.s2,
                inputFontSizeSM: 14,
                fontSize: 15,
                fontSizeIcon: 24,
                colorIcon: selectedTheme.colors.text.t5,
                colorIconHover: selectedTheme.colors.text.t5,
                colorTextPlaceholder: selectedTheme.colors.text.t2,
                // disabled
                colorBgContainerDisabled: selectedTheme.colors.background.b1,
                colorTextDisabled: selectedTheme.colors.text.t2,
                // error
                colorError: selectedTheme.colors.error.e3,
                colorErrorBorderHover: selectedTheme.colors.error.e3,
              },
              Select: {
                controlHeight: 44,
                borderRadius: 10,
                borderRadiusSM: 0, // border-radius of small elements i.e. option item
                colorBgContainer: selectedTheme.colors.background.b1,
                colorBorder: selectedTheme.colors.stroke.s1,
                colorPrimaryHover: selectedTheme.colors.stroke.s2,
                // outline
                controlOutline: selectedTheme.colors.primary.p1,
                controlOutlineWidth: 3,
                // select dropdown
                controlItemBgHover: selectedTheme.colors.stroke.s1,
                controlItemBgActive: selectedTheme.colors.stroke.s2,
                optionHeight: 40,
                optionPadding: "9px 14px",
                optionSelectedBg: selectedTheme.colors.link.l1,
                controlPaddingHorizontal: 0,
                fontSizeSM: 14,
                fontSize: 15,
                fontSizeIcon: 24,
                colorText: selectedTheme.colors.text.t4,
                colorIcon: selectedTheme.colors.text.t4,
                colorIconHover: selectedTheme.colors.text.t4,
                colorTextPlaceholder: selectedTheme.colors.text.t2,
                // disabled
                colorBgContainerDisabled: selectedTheme.colors.background.b1,
                colorTextDisabled: selectedTheme.colors.text.t2,
                // error
                colorError: selectedTheme.colors.error.e3,
                colorErrorBorderHover: selectedTheme.colors.error.e3,
              },
              Button: {
                // size
                controlHeightSM: 36,
                controlHeight: 40,
                controlHeightLG: 44,
                paddingInlineSM: 12,
                paddingInline: 14,
                paddingInlineLG: 16,
                // border
                borderRadiusSM: 10,
                borderRadius: 11,
                borderRadiusLG: 12,
                // font
                fontWeight: 450,
                contentFontSizeSM: 15,
                contentFontSize: 15,
                contentFontSizeLG: 16,
                contentLineHeightSM: 1.334,
                contentLineHeight: 1.334,
                contentLineHeightLG: 1.25,
                // icon
                onlyIconSizeSM: 20,
                onlyIconSize: 20,
                onlyIconSizeLG: 24,
                marginXS: 6,
                // danger
                colorError: selectedTheme.colors.error.e3,
                colorErrorBg: selectedTheme.colors.error.e3,
                colorErrorHover: selectedTheme.colors.error.e4,
                colorErrorBorderHover: selectedTheme.colors.error.e4,
                colorErrorActive: selectedTheme.colors.error.e5,
                dangerShadow: "none",
              },
              Radio: {
                colorBgContainer: selectedTheme.colors.background.b1,
                colorBorder: selectedTheme.colors.stroke.s2,
                dotSize: 10,
                radioSize: 20,
                colorPrimary: selectedTheme.colors.primary.p3,
              },
              Tooltip: {
                colorBgSpotlight: selectedTheme.colors.components.tooltip.background,
                paddingXS: 6,
                fontSize: 13,
                lineHeight: 1,
                boxShadowSecondary: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
                borderRadius: 8,
                controlHeight: 26,
                sizePopupArrow: 0,
              },
              Modal: {
                // content padding
                paddingMD: 0,
                paddingContentHorizontalLG: 0,
                // close icon
                colorBgTextHover: "transparent",
                colorIcon: selectedTheme.colors.text.t3,
                colorIconHover: selectedTheme.colors.text.t3,
                // background
                contentBg: selectedTheme.colors.components.modal.background,
                headerBg: selectedTheme.colors.components.modal.background,
                footerBg: selectedTheme.colors.components.modal.background,
                borderRadius: 10,
                marginXS: 10,
                // mask background
                colorBgMask: "rgba(0, 0, 0, 0.50)",
              },
              Dropdown: {
                controlItemBgActive: selectedTheme.colors.components.dropdown.active,
                controlItemBgHover: selectedTheme.colors.components.dropdown.hover,
                controlItemBgActiveHover: selectedTheme.colors.components.dropdown.hover,
                colorBgElevated: selectedTheme.colors.components.dropdown.background,
                borderRadiusLG: 12,
                boxShadowSecondary: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
                colorText: selectedTheme.colors.text.t5,
                marginXS: 10,
                lineHeight: 1.715,
                paddingBlock: 8,
                controlPaddingHorizontal: 14,
                paddingXXS: "6px 0",
                paddingXS: 14,
              },
              Message: {
                contentBg: selectedTheme.colors.components.dropdown.background,
                contentPadding: "8px 16px 8px 8px",
                fontSizeLG: 20,
                fontSize: 15,
                boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
                colorText: selectedTheme.colors.text.t4,
                borderRadiusLG: 10,
                lineHeight: 1.4,
              },
              Checkbox: {
                colorBgContainer: selectedTheme.colors.background.b1,
                colorBgContainerDisabled: selectedTheme.colors.background.b3,
                colorBorder: selectedTheme.colors.stroke.s2,
                controlInteractiveSize: 20,
                borderRadiusSM: 6,
              },
              Popover: {
                colorBgElevated:
                  selectedTheme.colors.components.dropdown.background,
                boxShadowSecondary: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
                titleMinWidth: 280,
                borderRadiusLG: 12,
              },
              Menu: {
                colorBgContainer:
                  selectedTheme.colors.components.dropdown.background,
                colorBgElevated:
                  selectedTheme.colors.components.dropdown.background,
                // menu item
                padding: 14,
                itemMarginBlock: 0,
                itemMarginInline: 0,
                itemBorderRadius: 0,
                itemActiveBg: selectedTheme.colors.components.dropdown.hover,
                itemHoverBg: selectedTheme.colors.components.dropdown.active,
                itemSelectedBg: selectedTheme.colors.components.dropdown.active,
                itemColor: selectedTheme.colors.text.t5,
                activeBarBorderWidth: 0,
                itemSelectedColor: selectedTheme.colors.text.t5,
                // menu divider
                colorSplit: selectedTheme.colors.stroke.s1,
              },
              Table: {
                headerColor: selectedTheme.colors.text.t6,
                headerBg: selectedTheme.colors.background.b2,
                headerSortActiveBg: selectedTheme.colors.background.b2,
                headerSortHoverBg: selectedTheme.colors.background.b2,
                headerSplitColor: selectedTheme.colors.background.b2,
                colorBgContainer: selectedTheme.colors.background.b1,
                // row
                rowSelectedHoverBg: selectedTheme.colors.background.b3,
                rowHoverBg: selectedTheme.colors.background.b3,
                rowSelectedBg: selectedTheme.colors.background.b2,
                borderColor: selectedTheme.colors.stroke.s1,
                cellPaddingBlock: 7.5,
                cellPaddingInline: 8,
                colorText: selectedTheme.colors.text.t5,
              },
              Drawer: {
                padding: 0,
                paddingLG: 0,
                colorBgElevated: selectedTheme.colors.components.modal.background,
                // mask background
                colorBgMask: "rgba(0, 0, 0, 0.50)",
              },
            },
          }}
        >
          {children}
        </ConfigProvider>
      </ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

const useCustomThemeContext = () => {
  const context = useContext(CustomThemeContext);

  if (context) {
    return context;
  }

  throw new Error(
    "useCustomThemeContext must be used within a CustomThemeContextProvider"
  );
};

export { CustomThemeContextProvider, useCustomThemeContext };
