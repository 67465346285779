import styled from "styled-components";

export const LoginVerificationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 320px;

    .signup-icon-circle {
        width: 80px;
        height: 80px;
        flex-shrink: 0;
        border-radius: 40px;
        background: ${({ theme }) => theme.colors.background.b2};
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 48px;
            height: 48px;
        }
    }

    .login-verificaiton-title {
        color: ${({ theme }) => theme.colors.text.t6};
        font-size: 24px;
        line-height: 150%;
    }

    .login-verificaiton-subtitle {
        color: ${({ theme }) => theme.colors.text.t4};
        font-size: 15px;
    }
`;

export const VerificationWrapper = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .verificaiton-modal-sub-title {
        color: ${({ theme }) => theme.colors.text.t4};
        font-size: 15px;
        line-height: 160%;
    }

    .footer-text {
        color: ${({ theme }) => theme.colors.text.t4};
        font-size: 15px;
        line-height: 160%;
        display: flex;
        gap: 8px;
    }

    .verification-box {
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-radius: 12px;
        border: 1px solid ${({ theme }) => theme.colors.stroke.s1};
        cursor: pointer;

        .verification-box-icon {
            width: 40px;
            height: 40px;
        }

        .verification-box-title {
            color:  ${({ theme }) => theme.colors.text.t5};
            font-size: 15px;
            font-weight: 450;
            line-height: 160%;
        }

    }
`;
