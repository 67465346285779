export const useCheckPasswordStrength = () => {

    function checkPasswordStrength(value) {
        if (!value) {
            return {
                percent: "0",
                color: "var(--grey-50)",
            };
        }

        const length = value.length;
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value);

        const criteriaMet = [
            length >= 8,
            hasUpperCase,
            hasLowerCase,
            hasNumber,
            hasSpecialChar,
        ].filter(Boolean).length;

        let percent;
        let color;

        switch (criteriaMet) {
            case 5:
                percent = "100";
                color = "var(--success-300)"; // Green
                break;
            case 4:
                percent = "80";
                color = "var(--warning-300)"; // Green
                break;
            case 3:
                percent = "60";
                color = "var(--warning-300)"; // Light Green
                break;
            case 2:
                percent = "40";
                color = "var(--warning-400)"; // Orange
                break;
            case 1:
                percent = "20";
                color = "var(--error-300)"; // Red
                break;
            default:
                percent = "0";
                color = "var(--grey-50)"; // Gray
                break;
        }

        return {
            percent,
            color,
        };
    }

    return {
        checkPasswordStrength,
    };
};
