import { useFormContext } from "react-hook-form";

import { CodeVerificationIcon } from "src/krispcall/assets";
import OtpInputField from "src/krispcall/components/molecules/fields/v3/OtpInputField";
import Button from "src/krispcall/components/atoms/button/v3/Button";

import { LoginVerificationWrapper } from "../Styles";

const MfaBackupCodeForm = ({recover, loading}) => {

    const { watch } = useFormContext();
    const formValues = watch();

    return (
        <LoginVerificationWrapper className="w-95">
            <div className="signup-icon-circle"><img src={CodeVerificationIcon} /></div>
            <div className="flex flex-col mb-4">
                <span className="login-verificaiton-title">2 step verification</span>
                <span className="login-verificaiton-subtitle">Please enter one of the backup code.</span>

                <OtpInputField
                    name="emailCode"
                    id="emailCode"
                    containerStyle="my-8"
                    autoFocus
                    numInputs={8}
                />

                <Button
                    loading={loading}
                    block
                    variant="primary"
                    disabled={formValues.emailCode?.length !== 8 || loading}
                    onClick={() => recover(formValues.emailCode)}
                >
                    Verify
                </Button>
            </div>
        </LoginVerificationWrapper>
    );
};
export default MfaBackupCodeForm;
