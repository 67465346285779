import styled from "styled-components";

export const ProviderButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.colors.stroke.s1};
    background: ${({ theme }) => theme.colors.background.b1};
    margin-bottom: 12px;

    span {
        color: ${({ theme }) => theme.colors.text.t5};
        text-align: center;
        font-size: 15px;
        font-weight: 420;
        line-height: 20px;
    }
`;
