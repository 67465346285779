// copied from ForgetPage for customization.

import React from "react";

import { Form, Select } from "antd";
import i18next from "i18next";
import {withRouter} from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import CustomGithubCorner from "src/common/CustomGithubCorner";
import { useChatBot } from "src/krispcall/hooks/useChatBot";
import { createMarkup } from "src/krispcall/utils/markup";

import * as PasswordChecker from "src/common/PasswordChecker";
import * as UserBackend from "src/backend/UserBackend";
import * as AuthBackend from "src/auth/AuthBackend";
import * as ApplicationBackend from "src/backend/ApplicationBackend";
import * as Util from "src/auth/Util";
import * as Setting from "src/Setting";

import { SendCodeInputCustom } from "./common/SendCodeInputCustom";
import { TopLabelInput } from "../../molecules/fields";
import { Button, Title, Text } from "../../atoms";
import { CrispChat } from "../../organisms";

const {Option} = Select;

class ForgotPageCustom extends React.Component {
  constructor(props) {
    super(props);
    this.updateFormValuesRef = this.updateFormValues.bind(this);
    this.setEmailOtpCodeRef = this.setEmailOtpCode.bind(this);
    this.state = {
      classes: props,
      applicationName: props.applicationName ?? props.match.params?.applicationName,
      msg: null,
      name: "",
      username: "",
      phone: "",
      email: "",
      dest: "",
      isVerifyTypeFixed: false,
      verifyType: "", // "email", "phone"
      current: 0,
      code: "",
      resend: true,
      countDown: 0,
      newPassword: "",
      oldPassword: "",
      buttonLoading: false,
    };

    this.form = React.createRef();
  }

  componentDidMount() {
    if (this.getApplicationObj() === undefined) {
      if (this.state.applicationName !== undefined) {
        this.getApplication();
      } else {
        Setting.showMessage("error", i18next.t("forget:Unknown forget type") + ": " + this.state.type);
      }
    }
    if (this.state.current === 1) {
      this.handleCountDown();
    }
  }

  getApplication() {
    if (this.state.applicationName === undefined) {
      return;
    }

    ApplicationBackend.getApplication("admin",
      this.state.applicationName
      )
      .then((res) => {
        if (!localStorage.getItem("params")) {
          Setting.showMessage("error", "Sesion Expired! Couldn't find Application!");
          window.location.href = res.data.homepageUrl;
        }
        if (res.status === "error") {
          Setting.showMessage("error", res.msg);
          return;
        }
        this.onUpdateApplication(res.data);
      });
  }
  getApplicationObj() {
    return this.props.application;
  }

  onUpdateApplication(application) {
    this.props.onUpdateApplication(application);
  }

  updateFormValues(field, value) {
    this.setState({ [field]: value });
  }

  isValidateForm() {
    if (this.state.newPassword.length < 2 || this.state.oldPassword < 2 || (this.state.oldPassword !== this.state.newPassword)) {
      return false;
    }
    return true;
  }

  onFormFinish(name, info, forms) {
    switch (name) {
    case "step1":
      const username = forms.step1.getFieldValue("username");
      this.updateFormValues("buttonLoading", true);

      AuthBackend.getEmailAndPhone(forms.step1.getFieldValue("organization"), username)
        .then((res) => {
          this.updateFormValues("buttonLoading", false);
          if (res.status === "ok") {
            const phone = res.data.phone;
            const email = res.data.email;

            if (!phone && !email) {
              Setting.showMessage("error", "no verification method!");
            } else {
              this.setState({
                name: res.data.name,
                phone: phone,
                email: email,
              });

              const saveFields = (type, dest, fixed) => {
                this.setState({
                  verifyType: type,
                  isVerifyTypeFixed: fixed,
                  dest: dest,
                });
              };

              switch (res.data2) {
              case "email":
                saveFields("email", email, true);
                break;
              case "phone":
                saveFields("phone", phone, true);
                break;
              case "username":
                phone !== "" ? saveFields("phone", phone, false) : saveFields("email", email, false);
              }

              this.setState({
                current: 1,
              });
            }
          } else {
            Setting.showMessage("error", res.msg);
          }
        });
      break;
    case "step2":
      this.updateFormValues("buttonLoading", true);
      UserBackend.verifyCode({
        application: forms.step2.getFieldValue("application"),
        organization: forms.step2.getFieldValue("organization"),
        username: forms.step2.getFieldValue("dest"),
        name: this.state.name,
        code: this.state.code,
        type: "login",
      }).then(res => {
        this.updateFormValues("buttonLoading", false);
        if (res.status === "ok") {
          this.setState({current: 2});
        } else {
          Setting.showMessage("error", res.msg);
        }
      });

      break;
    default:
      break;
    }
  }

  onFinish(values) {
    this.updateFormValues("buttonLoading", true);
    values.username = this.state.name;
    values.userOwner = this.getApplicationObj()?.organizationObj.name;
    UserBackend.setPassword(values.userOwner, values.username, values.oldPassword, values?.newPassword, this.state.code).then(res => {
      if (res.status === "ok") {
        this.updateFormValues("buttonLoading", true);
        const linkInStorage = sessionStorage.getItem("signinUrl");
        if (linkInStorage !== null && linkInStorage !== "") {
          // incase we need to revert
          // Setting.goToLinkSoft(this, linkInStorage);
          window.location.href = linkInStorage;
        } else {
          Setting.redirectToLoginPage(this.getApplicationObj(), this.props.history);
        }
      } else {
        Setting.showMessage("error", res.msg);
      }
    });
  }

  onFinishFailed(values, errorFields) {}

  renderOptions() {
    const options = [];

    if (this.state.phone !== "") {
      options.push(
        <Option key={"phone"} value={this.state.phone} >
          &nbsp;&nbsp;{this.state.phone}
        </Option>
      );
    }

    if (this.state.email !== "") {
      options.push(
        <Option key={"email"} value={this.state.email} >
          &nbsp;&nbsp;{this.state.email}
        </Option>
      );
    }

    return options;
  }

  setEmailOtpCode(otp) {
    this.updateFormValues("code", otp);
  }

  handleCountDown = (leftTime = 60) => {
    let leftTimeSecond = leftTime;
    this.updateFormValues("countDown", leftTimeSecond);
    const countDown = () => {
      leftTimeSecond--;
      this.updateFormValues("countDown", leftTimeSecond);
      if (leftTimeSecond === 0) {
        this.updateFormValues("resend", false);
        return;
      }
      setTimeout(countDown, 1000);
    };
    setTimeout(countDown, 1000);
  };

  renderForm(application) {
    const { openChat } = useChatBot();
    const oAuthParams = localStorage.getItem("params") ? JSON.parse(atob(localStorage.getItem("params"))) : Util.getOAuthGetParameters();
    return (
      <Form.Provider onFormFinish={(name, {info, forms}) => {
        this.onFormFinish(name, info, forms);
      }}>
        {/* STEP 1: input username -> get email & phone */}
        {this.state.current === 0 ?
          <Form
            ref={this.form}
            name="step1"
            // eslint-disable-next-line no-console
            onFinishFailed={(errorInfo) => console.log(errorInfo)}
            initialValues={{
              application: application.name,
              organization: application.organization,
            }}
            className="w-[413px]"
            size="large"
          >
            <Title className="mb-4">
                    {i18next.t("forgotPassword", "Forgot Password")}
            </Title>
            <Text className="text-base mb-6 text-[#6E6681]">
              {i18next.t("forgotPasswordDescription", "Don’t worry! We got your back. Enter your registered email address and we will send you a 6-digit code")}
            </Text>
            <Form.Item
              hidden
              name="application"
              rules={[
                {
                  required: true,
                  message: i18next.t("application:Please input your application!"),
                },
              ]}
            />
            <Form.Item
              hidden
              name="organization"
              rules={[
                {
                  required: true,
                  message: i18next.t("application:Please input your organization!"),
                },
              ]}
            />
            <TopLabelInput
              name="username"
              label="Email Address"
              placeholder={i18next.t("login:username, Email or phone")}
              updateFormValues={this.updateFormValuesRef}
              rules={[
                {
                  required: true,
                  message: i18next.t("forget:Please input your username!"),
                  whitespace: true,
                },
              ]}
            />
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!Setting.isValidEmail(this.state.username)}
                loading={this.state.buttonLoading}
              >
                {i18next.t("forget:Continue")}
              </Button>
              <Text className="mt-6 mb-6 text-[#6E6681]">
              {i18next.t("goBack", "Go back to")}&nbsp;
              <Link
                  to={`/login/oauth/authorize?client_id=${oAuthParams?.clientId}&response_type=${oAuthParams?.responseType}&redirect_uri=${oAuthParams?.redirectUri}&scope=${oAuthParams?.scope}&state=${oAuthParams?.state}&code_challenge=${oAuthParams?.codeChallenge}&code_challenge_method=${oAuthParams?.challengeMethod}`}
                  className="text-info"
                  target={"_self"}
                >Sign in</Link>
              </Text>
            </Form.Item>
          </Form> : null}

        {/* STEP 2: verify email or phone */}
        {this.state.current === 1 ? <Form
          ref={this.form}
          name="step2"
          onFinishFailed={(errorInfo) =>
            this.onFinishFailed(
              errorInfo.values,
              errorInfo.errorFields,
              errorInfo.outOfDate
            )
          }
          onValuesChange={(changedValues, allValues) => {
            const verifyType = changedValues.dest?.indexOf("@") === -1 ? "phone" : "email";
            this.setState({
              dest: changedValues.dest,
              verifyType: verifyType,
            });
          }}
          initialValues={{
            application: application.name,
            organization: application.organization,
            dest: this.state.dest,
          }}
          className="w-[413px]"
          size="large"
        >
          <Form.Item
            style={{height: 0, visibility: "hidden"}}
            name="application"
            rules={[
              {
                required: true,
                message: i18next.t("application:Please input your application!"),
              },
            ]}
          />
          <Title className="mb-4">
                  {i18next.t("forgotPassword", "Forgot Password")}
          </Title>
          <Text className="text-base mb-6 text-[#6E6681]">
            {i18next.t("forgotPasswordEmailDescription1", "Please enter the 6-digit code we just sent you at")}&nbsp;
            <b>{this.state.username}</b> {i18next.t("forgotPasswordEmailDescription2", "to continue.")}
          </Text>
          <Form.Item
            hidden
            name="organization"
            rules={[
              {
                required: true,
                message: i18next.t("application:Please input your organization!"),
              },
            ]}
          />

          {/* otp input and submit button */}
          <SendCodeInputCustom
            name="code"
            application={application}
            state={this.state}
            setEmailOtpCode={this.setEmailOtpCodeRef}
            method={"forget"}
            onButtonClickArgs={[this.state.dest, this.state.verifyType, Setting.getApplicationName(this.getApplicationObj()), this.state.name]}
            rules={[
              {
                required: true,
                message: i18next.t("code:Please input your verification code!"),
              },
            ]}
          />

          <Link
            to={"#"}
            className="text-info"
            target={"_self"}
            onClick={() => this.updateFormValues("current", 0)}
          >
            {i18next.t("useDifferentEmail", "Use a different email address.")}
          </Link>
          <br></br>
          <Link
            to={"#"}
            className="text-info"
            target={"_self"}
            onClick={openChat}
          >
            {i18next.t("Contact support", "Contact support")}
          </Link>
          <Text className="mt-6 mb-6 text-[#6E6681]">
          {i18next.t("goBack", "Go back to")}&nbsp;
          <Link
              to={"/login"}
              className="text-info"
              target={"_self"}
            >Sign in</Link>
          </Text>
        </Form> : null}

        {/* STEP 3 */}
        {this.state.current === 2 ?
          <Form
            ref={this.form}
            name="step3"
            onFinish={(values) => this.onFinish(values)}
            onFinishFailed={(errorInfo) =>
              this.onFinishFailed(
                errorInfo.values,
                errorInfo.errorFields,
                errorInfo.outOfDate
              )
            }
            initialValues={{
              application: application.name,
              organization: application.organization,
            }}
            className="w-[413px]"
            size="large"
          >
            <Title className="mb-4">
                    {i18next.t("resetPassword", "Reset Password")}
            </Title>
            <Text className="text-base mb-6 text-[#6E6681]">
              {i18next.t("resetPasswordDescription", "Strong passwords include numbers, letters and punctuation marks.")}
            </Text>
            <Form.Item
              hidden
              name="application"
              rules={[
                {
                  required: true,
                  message: i18next.t("application:Please input your application!"),
                },
              ]}
            />
            <Form.Item
              hidden
              name="organization"
              rules={[
                {
                  required: true,
                  message: i18next.t("application:Please input your organization!"),
                },
              ]}
            />
            <TopLabelInput
              name="newPassword"
              type="password"
              label={i18next.t("general:Password")}
              updateFormValues={this.updateFormValuesRef}
              showStrength={true}
              rules={[
                {
                  required: true,
                  validateTrigger: "onChange",
                  validator: (rule, value) => {
                    const errorMsg = PasswordChecker.checkPasswordComplexity(value, application.organizationObj.passwordOptions);
                    if (errorMsg === "") {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(errorMsg);
                    }
                  },
                },
              ]}
              hasFeedback
            />
            <TopLabelInput
              name="oldPassword"
              type="password"
              label={i18next.t("general:Confirm Password")}
              hasFeedback
              dependencies={["newPassword"]}
              updateFormValues={this.updateFormValuesRef}
              rules={[
                {
                  required: true,
                  message: i18next.t("signup:Please confirm your password!"),
                },
                ({getFieldValue}) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("newPassword") === value || getFieldValue("oldPassword") === " ") {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      i18next.t("signup:Your confirmed password is inconsistent with the password!")
                    );
                  },
                }),
              ]}
            />
            <Form.Item hidden={this.state.current !== 2}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!this.isValidateForm()}
                loading={this.state.buttonLoading}
              >
                {i18next.t("forget:Reset Password")}
              </Button>
            </Form.Item>
          </Form> : null}
      </Form.Provider>
    );
  }

  stepBack() {
    if (this.state.current > 0) {
      this.setState({
        current: this.state.current - 1,
      });
    } else if (this.props.history.length > 1) {
      this.props.history.goBack();
    } else {
      Setting.redirectToLoginPage(this.getApplicationObj(), this.props.history);
    }
  }

  parseOffset(offset) {
      if (offset === 2 || offset === 4 || Setting.inIframe() || Setting.isMobile()) {
        return "0 auto";
      }
      if (offset === 1) {
        return "0 10%";
      }
      if (offset === 3) {
        return "0 60%";
      }
    }

  render() {
    const application = this.getApplicationObj();
    if (application === undefined) {
      return null;
    }
    if (application === null) {
      return Util.renderMessageLarge(this, this.state.msg);
    }

    return (
      <React.Fragment>
        <CustomGithubCorner />
        <div className="login-content" style={{margin: this.props.preview ?? this.parseOffset(application.formOffset)}}>
          {Setting.inIframe() || Setting.isMobile() ? null : <div dangerouslySetInnerHTML={createMarkup(application.formCss)} />}
          {Setting.inIframe() || !Setting.isMobile() ? null : <div dangerouslySetInnerHTML={createMarkup(application.formCssMobile)} />}
          <div className="forget-panel">
            <div className="side-image" style={{display: application.formOffset !== 4 ? "none" : null}}>
              <div dangerouslySetInnerHTML={createMarkup(application.formSideHtml)} />
            </div>
            <div className="login-form-container">
              <div className="login-logo-box w-[413px]">
                {
                  Setting.renderHelmet(application)
                }
                {
                  Setting.renderLogo(application)
                }
                {this.renderForm(application)}
              </div>
            </div>
          </div>
        </div>
        <CrispChat />
      </React.Fragment>
    );
  }
}

export default withRouter(ForgotPageCustom);
