import { Input } from "antd";
import styled, { css } from "styled-components";

const PasswordErrorCSS = css`
  &.ant-input-password:not(.ant-input-disabled) {
    background: ${({ theme }) => theme.colors.error.e1};
    input {
      background: inherit;
    }
  }
`;

export const StyledPasswordInputField = styled(Input.Password)`
  &.ant-input-password {
    .ant-input-suffix {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  ${props => props?.status === "error" && PasswordErrorCSS}
`;

export default StyledPasswordInputField;
