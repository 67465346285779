// copied from CaptchaModal(Default from Casdoor) for customization.
import React, { useEffect } from "react";

import {Col, Input, Row} from "antd";
import {SafetyOutlined} from "@ant-design/icons";
import i18next from "i18next";

import { default as CaptchaWidgetCustom } from "./CaptchaWidgetCustom";

import * as UserBackend from "../../../../backend/UserBackend";

const RecapthcaV2 = (props) => {
  const {owner, name, onOk, isCurrentProvider, setCaptchaVerified} = props;

  const [captchaType, setCaptchaType] = React.useState("none");
  const [clientId, setClientId] = React.useState("");
  const [clientSecret, setClientSecret] = React.useState("");
  const [subType, setSubType] = React.useState("");
  const [clientId2, setClientId2] = React.useState("");
  const [clientSecret2, setClientSecret2] = React.useState("");

  const [captchaImg, setCaptchaImg] = React.useState("");
  const [captchaToken, setCaptchaToken] = React.useState("");

  const defaultInputRef = React.useRef(null);

  useEffect(() => {
      loadCaptcha();
  }, []);

  useEffect(() => {
    if (setCaptchaVerified) {
      if (captchaToken === "") {
        setCaptchaVerified(false);
        return undefined;
      }
      if (captchaType === "reCAPTCHA") {
        handleOk();
        setCaptchaVerified(true);
        return undefined; // No need to execute further
      }
      const regex = /^\d{5}$/;
      if (regex.test(captchaToken)) {
        handleOk();
        setCaptchaVerified(true);
      }
    } else {
      // if classed based component then got else run handleOk
      handleOk();
    }
  }, [captchaToken]);

  const handleOk = () => {
    onOk?.(captchaType, captchaToken, clientSecret, "");
  };

  const loadCaptcha = () => {
    UserBackend.getCaptcha(owner, name, isCurrentProvider).then((res) => {
      if (res.type === "none") {
        handleOk();
      } else if (res.type === "Default") {
        setClientSecret(res.captchaId);
        setCaptchaImg(res.captchaImage);
        setCaptchaType("Default");
      } else if (res.type === "reCAPTCHA v3-Standard") {
        setCaptchaType(res.type);
        setClientId(res.clientId);
        setClientSecret(res.clientSecret);
        onOk?.(res.type, "", res.clientSecret, res.clientId);
      } else {
        setCaptchaType(res.type);
        setClientId(res.clientId);
        setClientSecret(res.clientSecret);
        setSubType(res.subType);
        setClientId2(res.clientId2);
        setClientSecret2(res.clientSecret2);
      }
    });
  };

  const renderDefaultCaptcha = () => {
    return (
      <Col style={{textAlign: "center"}}>
        <div style={{display: "inline-block"}}>
          <Row
            style={{
              backgroundImage: `url('data:image/png;base64,${captchaImg}')`,
              backgroundRepeat: "no-repeat",
              height: "80px",
              width: "200px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginBottom: "20px",
            }}
          />
          <Row>
            <Input
              ref={defaultInputRef}
              style={{width: "200px"}}
              value={captchaToken}
              prefix={<SafetyOutlined />}
              placeholder={i18next.t("general:Captcha")}
              onPressEnter={handleOk}
              onChange={(e) => setCaptchaToken(e.target.value)}
            />
          </Row>
        </div>
      </Col>
    );
  };

  const onChange = (token) => {
    setCaptchaToken(token);
  };

  const renderCaptcha = () => {
    if (captchaType === "Default") {
      return renderDefaultCaptcha();
    } else {
      return (
        <Col>
          <Row>
            <CaptchaWidgetCustom
              captchaType={captchaType}
              subType={subType}
              siteKey={clientId}
              clientSecret={clientSecret}
              onChange={onChange}
              clientId2={clientId2}
              clientSecret2={clientSecret2}
            />
          </Row>
        </Col>
      );
    }
  };

  return (
      <div>
        {
          renderCaptcha()
        }
      </div>
  );
};
export default RecapthcaV2;
