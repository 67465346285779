// copied from MfaAuthVerifyFormCustom

import { useState } from "react";

import i18next from "i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";

import { EmailMfaType, RecoveryMfaType, SmsMfaType } from "src/auth/MfaSetupPage";
import { mfaAuth } from "src/auth/mfa/MfaVerifyForm";
import { Button, Text, Title } from "src/krispcall/components/atoms";
import { useChatBot } from "src/krispcall/hooks/useChatBot";
import { BackIcon } from "src/krispcall/assets";
import { OtpInputField } from "src/krispcall/components/molecules/fields";

import * as Setting from "src/Setting";
import * as AuthBackend from "src/auth/AuthBackend";

import MfaVerifySmsForm from "./MfaVerifySmsFormCustom";
import MfaVerifyTotpFormCustom from "./MfaVerifyTotpFormCustom";

export const NextMfa = "NextMfa";
export const RequiredMfa = "RequiredMfa";

export function MfaAuthVerifyFormCustom({formValues, authParams, mfaProps, application, onSuccess, onFail, state, setEmailOtpCode, updateFormValues}) {
  formValues.password = "";
  formValues.username = "";
  const [loading, setLoading] = useState(false);
  const [mfaType, setMfaType] = useState(mfaProps.mfaType);
  const [recoveryCode, setRecoveryCode] = useState("");
  const {openChat} = useChatBot();
  const verify = ({passcode}) => {
    setLoading(true);
    const values = {...formValues, passcode, mfaType};
    const loginFunction = formValues.type === "cas" ? AuthBackend.loginCas : AuthBackend.login;
    loginFunction(values, authParams).then((res) => {
      if (res.status === "ok") {
        onSuccess(res);
      } else {
        onFail(res.msg);
      }
    }).catch((res) => {
      onFail(res.message);
    }).finally(() => {
      setLoading(false);
    });
  };

  const recover = () => {
    setLoading(true);
    const values = {...formValues, recoveryCode};
    const loginFunction = formValues.type === "cas" ? AuthBackend.loginCas : AuthBackend.login;
    loginFunction(values, authParams).then((res) => {
      if (res.status === "ok") {
        onSuccess(res);
      } else {
        onFail(res.msg);
      }
    }).catch((res) => {
      onFail(res.message);
    }).finally(() => {
      setLoading(false);
    });
  };

  if (mfaType !== RecoveryMfaType) {
    return (
      <div className="w-[450px]">
        <div className="login-logo-box">
            {
                Setting.renderLogo(application)
            }
        </div>
        <Title className="mb-4">
              {i18next.t("twoFactorAuthTitle", "Two-factor authentication")}
        </Title>
        <Text className="text-base mb-6 text-[#6E6681]">
          {i18next.t("twoFactorAuthSubTitle", "Please enter the 6-digit code you see in your authenticator app.")}
        </Text>
        {mfaType === SmsMfaType || mfaType === EmailMfaType ? (
          <MfaVerifySmsForm
            mfaProps={mfaProps}
            method={mfaAuth}
            onFinish={verify}
            application={application}
            state={state}
            setEmailOtpCode={setEmailOtpCode}
            updateFormValues={updateFormValues}
          />) : (
          <>
            <MfaVerifyTotpFormCustom
              mfaProps={mfaProps}
              onFinish={verify}
              state={state}
              setAuthOtpCode={setEmailOtpCode}
              updateFormValues={updateFormValues}
              loading={loading}
              />
            <div className="flex flex-col gap-2">
              <span className="mt-2">
                {i18next.t("mfa:Don’t have access to the app? ")}
                <a onClick={() => {
                  setMfaType("recovery");
                }}>
                  {i18next.t("mfa:Use backup code")}
                </a>
              </span>

              <span>
                {i18next.t("mfa:Still having trouble? ")}
                <Link
                  to={"#"}
                  className="text-info"
                  target={"_self"}
                  onClick={openChat}
                >
                  {i18next.t("Contact support", "Contact support")}
                </Link>
              </span>
            </div>

            <div className="back-btn mt-10" onClick={() => updateFormValues("getVerifyTotp", undefined)}>
              <img src={BackIcon} height={"20px"} />
              <Text className="font-semibold">Go Back</Text>
            </div>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="w-[450px]">
        <div className="login-logo-box">
            {
                Setting.renderLogo(application)
            }
        </div>
        <Title className="mb-4">
              {i18next.t("backupCodeTitle", "Backup Code")}
        </Title>
        <Text className="text-base mb-6 text-[#6E6681]">
          {i18next.t("backupCodeSubTitle", "Please enter the backup code that was given to you when you set up the authenticator app")}
        </Text>

        <OtpInputField
          numInputs={8}
          value={recoveryCode}
          setEmailOtpCode={setRecoveryCode}
          containerStyle={{ marginBottom: "24px" }} />

        <Button
          style={{ width: "100%", marginBottom: 20 }}
          size={"large"}
          disabled={recoveryCode.length < 8}
          loading={loading}
          type={"primary"}
          onClick={() => {
            recover();
          }}>
          {i18next.t("forget:Verify")}
        </Button>
        {/* we don't have sms verification for now! commenting out for now */}
        {/* <span style={{float: "right"}}>
          {i18next.t("mfa:Have problems?")}
          <a onClick={() => {
            setMfaType(mfaProps.mfaType);
          }}>
            {i18next.t("mfa:Use SMS verification code")}
          </a>
        </span> */}
        <div className="back-btn mt-10" onClick={() => updateFormValues("getVerifyTotp", undefined)}>
          <img src={BackIcon} height={"20px"} />
          <Text className="font-semibold">Go Back</Text>
        </div>
      </div>
    );
  }
}
